import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {
    QuestionnaireCombinedAnswers
} from "../../interfaces/cancel-subscription-questionaire/questionnaire-combined-answers";

@Injectable({
    providedIn: 'root'
})
export class StripeService {
    stripePath = '/stripe/';

    constructor(private apiService: ApiService) {
    }

    public getStripeProductsOnOffer() {
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        return this.apiService.post(this.stripePath + 'products/list', {
            timezone
        });
    }

    public createStripeCheckout(priceId: string, productId, coachEmail) {
        return this.apiService.post(this.stripePath + 'create/checkout', {
            priceId, quantity: 1, returnUrl: `${window.location.origin}/#/subscription/activated`, productId, coachEmail
        });
    }

    public stripeQonversionPayment(sessionId, productId) {
        return this.apiService.post(this.stripePath + 'qonversion-payment', {sessionId, productId});
    }

    public stripeCancelSubscription() {
        return this.apiService.post(this.stripePath + 'cancel-subscription', {});
    }

    public cancelSubscriptionFeedback(questionnaireAnswers: QuestionnaireCombinedAnswers) {
        return this.apiService.post(this.stripePath + 'cancel-subscription-feedback', {...questionnaireAnswers});
    }

    public stripeSubscriptionAutoRenewTurnedOff(subscriptionId) {
        return this.apiService.post(this.stripePath + 'get-subscription-auto-renew-off', {subscriptionId});
    }

    public checkUserSubscription(userId: string) {
        return this.apiService.post(this.stripePath + 'check-subscription', { userId });
    }

    public getSubscriptionDetails() {
        return this.apiService.post(this.stripePath + 'subscription-details', {});
    }

    public getCustomerPortal() {
        return this.apiService.post(this.stripePath + 'customer-portal', {});
    }
}



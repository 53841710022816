import {
    PricingPhaseRecurrenceMode, PricingPhaseType, ProductType, SubscriptionPeriodUnit
} from '../enums/qonversion-enums';

import {WebEntitlement} from './qonversion/web/qonversion-web-entitlements';

export interface SkuDetails {
    description: string;
    freeTrialPeriod: string;
    iconUrl: string;
    introductoryPrice: string;
    introductoryPriceAmountMicros: number;
    introductoryPriceCycles: number;
    introductoryPricePeriod: string;
    originalJson: string;
    originalPrice: string;
    originalPriceAmountMicros: number;
    price: string;
    priceAmountMicros: number;
    priceCurrencyCode: string;
    sku: string;
    subscriptionPeriod: string;
    title: string;
    type: string;
    hashCode: number;
    toString: string; // Note: `toString` is usually a method, here it's typed as a string for consistency
}

export enum EntitlementRenewState {
    NON_RENEWABLE = 'non_renewable',
    UNKNOWN = 'unknown',
    WILL_RENEW = 'will_renew',
    CANCELED = 'canceled',
    BILLING_ISSUE = 'billing_issue',
}

export enum EntitlementSource {
    UNKNOWN = 'Unknown', APP_STORE = 'AppStore', PLAY_STORE = 'PlayStore', STRIPE = 'Stripe', MANUAL = 'Manual',
}


export enum TransactionEnvironment {
    PRODUCTION = 'Production', SANDBOX = 'Sandbox'
}

export enum TransactionOwnershipType {
    OWNER = 'Owner', FAMILY_SHARING = 'FamilySharing'
}

export enum EntitlementGrantType {
    PURCHASE = 'Purchase', FAMILY_SHARING = 'FamilySharing', OFFER_CODE = 'OfferCode', MANUAL = 'Manual',
}

export enum TransactionType {
    UNKNOWN = 'Unknown',
    SUBSCRIPTION_STARTED = 'SubscriptionStarted',
    SUBSCRIPTION_RENEWED = 'SubscriptionRenewed',
    TRIAL_STARTED = 'TrialStarted',
    INTRO_STARTED = 'IntroStarted',
    INTRO_RENEWED = 'IntroRenewed',
    NON_CONSUMABLE_PURCHASE = 'NonConsumablePurchase'
}

export enum UserPropertyKey {
    EMAIL = 'Email',
    NAME = 'Name',
    KOCHAVA_DEVICE_ID = 'KochavaDeviceId',
    APPS_FLYER_USER_ID = 'AppsFlyerUserId',
    ADJUST_AD_ID = 'AdjustAdId',
    CUSTOM_USER_ID = 'CustomUserId',
    FACEBOOK_ATTRIBUTION = 'FacebookAttribution', // Android only
    FIREBASE_APP_INSTANCE_ID = 'FirebaseAppInstanceId',
    APP_SET_ID = 'AppSetId', // Android only
    ADVERTISING_ID = 'AdvertisingId', // iOS only
    APP_METRICA_DEVICE_ID = 'AppMetricaDeviceId',
    APP_METRICA_USER_PROFILE_ID = 'AppMetricaUserProfileId',
    PUSH_WOOSH_HW_ID = 'PushWooshHwId',
    PUSH_WOOSH_USER_ID = 'PushWooshUserId',
    CUSTOM = 'Custom',
}

export enum AttributionProvider {
    APPSFLYER = 'AppsFlyer', BRANCH = 'Branch', ADJUST = 'Adjust', APPLE_SEARCH_ADS = 'AppleSearchAds', // ios only
    APPLE_AD_SERVICES = 'AppleAdServices', // ios only
}

/**
 * A policy used for purchase updates on Android, which describes
 * how to migrate from purchased plan to a new one.
 *
 * Used in {@link PurchaseUpdateModel} class for purchase updates.
 */
export enum PurchaseUpdatePolicy {
    /**
     * The new plan takes effect immediately, and the user is charged full price of new plan
     * and is given a full billing cycle of subscription, plus remaining prorated time
     * from the old plan.
     */
    CHARGE_FULL_PRICE = 'ChargeFullPrice',

    /**
     * The new plan takes effect immediately, and the billing cycle remains the same.
     */
    CHARGE_PRORATED_PRICE = 'ChargeProratedPrice',

    /**
     * The new plan takes effect immediately, and the remaining time will be prorated
     * and credited to the user.
     */
    WITH_TIME_PRORATION = 'WithTimeProration',

    /**
     * The new purchase takes effect immediately, the new plan will take effect
     * when the old item expires.
     */
    DEFERRED = 'Deferred',

    /**
     * The new plan takes effect immediately, and the new price will be charged
     * on next recurrence time.
     */
    WITHOUT_PRORATION = 'WithoutProration',

    /**
     * Unknown police.
     */
    UNKNOWN = 'Unknown',
}

export interface Transaction {
    originalTransactionId: string;
    transactionId: string;
    transactionDate: Date;
    environment: TransactionEnvironment;
    ownershipType: TransactionOwnershipType;
    type: TransactionType;
    expirationDate?: Date;
    transactionRevocationDate?: Date;
    offerCode?: string;
}

export enum EntitlementsCacheLifetime {
    WEEK = 'Week',
    TWO_WEEKS = 'TwoWeeks',
    MONTH = 'Month',
    TWO_MONTHS = 'TwoMonths',
    THREE_MONTHS = 'ThreeMonths',
    SIX_MONTHS = 'SixMonths',
    YEAR = 'Year',
    UNLIMITED = 'Unlimited',
}

export const SKPeriodUnit = {
    0: 'DAY', 1: 'WEEK', 2: 'MONTH', 3: 'YEAR',
} as const;
export type SKPeriodUnits = typeof SKPeriodUnit[keyof typeof SKPeriodUnit];

export const SKProductDiscountType = {
    0: 'INTRODUCTORY', 1: 'SUBSCRIPTION',
} as const;

export type SKProductDiscountTypes = typeof SKProductDiscountType[keyof typeof SKProductDiscountType];

export const SKProductDiscountPaymentMode = {
    0: 'PAY_AS_YOU_GO', 1: 'PAY_UP_FRONT', 2: 'FREE_TRIAL',
} as const;
export type SKProductDiscountPaymentModes = typeof SKProductDiscountPaymentMode[keyof typeof SKProductDiscountPaymentMode];

export const OfferingTag = {
    '-1': 'UNKNOWN', 0: 'NONE', 1: 'MAIN',
} as const;
export type OfferingTags = typeof OfferingTag[keyof typeof OfferingTag];

export enum IntroEligibilityStatus {
    UNKNOWN = 'unknown',
    NON_INTRO_OR_TRIAL_PRODUCT = 'non_intro_or_trial_product',
    ELIGIBLE = 'intro_or_trial_eligible',
    INELIGIBLE = 'intro_or_trial_ineligible',
}

export enum ExperimentGroupType {
    UNKNOWN = 'unknown', CONTROL = 'control', TREATMENT = 'treatment',
}

export enum RemoteConfigurationSourceType {
    UNKNOWN = 'unknown',
    EXPERIMENT_CONTROL_GROUP = 'experiment_control_group',
    EXPERIMENT_TREATMENT_GROUP = 'experiment_treatment_group',
    REMOTE_CONFIGURATION = 'remote_configuration',
}

export enum RemoteConfigurationAssignmentType {
    UNKNOWN = 'unknown', AUTO = 'auto', MANUAL = 'manual',
}

export enum ActionResultType {
    UNKNOWN = 'unknown',
    URL = 'url',
    DEEPLINK = 'deeplink',
    NAVIGATION = 'navigate',
    PURCHASE = 'purchase',
    RESTORE = 'restore',
    CLOSE = 'close',
}

export enum AutomationsEventType {
    UNKNOWN = 'unknown',
    TRIAL_STARTED = 'trial_started',
    TRIAL_CONVERTED = 'trial_converted',
    TRIAL_CANCELED = 'trial_canceled',
    TRIAL_BILLING_RETRY = 'trial_billing_retry_entered',
    SUBSCRIPTION_STARTED = 'subscription_started',
    SUBSCRIPTION_RENEWED = 'subscription_renewed',
    SUBSCRIPTION_REFUNDED = 'subscription_refunded',
    SUBSCRIPTION_CANCELED = 'subscription_canceled',
    SUBSCRIPTION_BILLING_RETRY = 'subscription_billing_retry_entered',
    IN_APP_PURCHASE = 'in_app_purchase',
    SUBSCRIPTION_UPGRADED = 'subscription_upgraded',
    TRIAL_STILL_ACTIVE = 'trial_still_active',
    TRIAL_EXPIRED = 'trial_expired',
    SUBSCRIPTION_EXPIRED = 'subscription_expired',
    SUBSCRIPTION_DOWNGRADED = 'subscription_downgraded',
    SUBSCRIPTION_PRODUCT_CHANGED = 'subscription_product_changed',
}

export enum QonversionErrorCode {
    UNKNOWN = 'Unknown', // Unknown error
    API_RATE_LIMIT_EXCEEDED = 'ApiRateLimitExceeded', // API requests rate limit exceeded
    APPLE_STORE_ERROR = 'AppleStoreError', // Apple Store error received
    BACKEND_ERROR = 'BackendError', // There was a backend error
    BILLING_UNAVAILABLE = 'BillingUnavailable', // The Billing service is unavailable on the device
    CLIENT_INVALID = 'ClientInvalid', // Client is not allowed to issue the request, etc
    CLOUD_SERVICE_NETWORK_CONNECTION_FAILED = 'CloudServiceNetworkConnectionFailed', // The device could not connect to the network
    CLOUD_SERVICE_PERMISSION_DENIED = 'CloudServicePermissionDenied', // User is not allowed to access cloud service information
    CLOUD_SERVICE_REVOKED = 'CloudServiceRevoked', // User has revoked permission to use this cloud service
    FAILED_TO_RECEIVE_DATA = 'FailedToReceiveData', // Could not receive data
    FEATURE_NOT_SUPPORTED = 'FeatureNotSupported', // The requested feature is not supported
    FRAUD_PURCHASE = 'FraudPurchase', // Fraud purchase was detected
    INCORRECT_REQUEST = 'IncorrectRequest', // Request failed
    INTERNAL_ERROR = 'InternalError', // Internal backend error
    INVALID_CLIENT_UID = 'InvalidClientUid', // Client Uid is invalid or not set
    INVALID_CREDENTIALS = 'InvalidCredentials', // Access token is invalid or not set
    INVALID_STORE_CREDENTIALS = 'InvalidStoreCredentials', // This account does not have access to the requested application
    LAUNCH_ERROR = 'LaunchError', // There was an error while launching Qonversion SDK
    NETWORK_CONNECTION_FAILED = 'NetworkConnectionFailed', // There was a network issue. Make sure that the Internet connection is available on the device
    OFFERINGS_NOT_FOUND = 'OfferingsNotFound', // No offerings found
    PAYMENT_INVALID = 'PaymentInvalid', // Purchase identifier was invalid, etc.
    PAYMENT_NOT_ALLOWED = 'PaymentNotAllowed', // This device is not allowed to make the payment
    PLAY_STORE_ERROR = 'PlayStoreError', // There was an issue with the Play Store service
    PRIVACY_ACKNOWLEDGEMENT_REQUIRED = 'PrivacyAcknowledgementRequired', // User needs to acknowledge Apple's privacy policy
    PRODUCT_ALREADY_OWNED = 'ProductAlreadyOwned', // Failed to purchase since item is already owned
    PRODUCT_NOT_FOUND = 'ProductNotFound', // Failed to purchase since the Qonversion product was not found
    PRODUCT_NOT_OWNED = 'ProductNotOwned', // Failed to consume purchase since item is not owned
    PROJECT_CONFIG_ERROR = 'ProjectConfigError', // The project is not configured or configured incorrectly in the Qonversion Dashboard
    PURCHASE_CANCELED = 'PurchaseCanceled', // User pressed back or canceled a dialog for purchase
    PURCHASE_INVALID = 'PurchaseInvalid', // Failure of purchase
    PURCHASE_PENDING = 'PurchasePending', // Purchase is pending
    PURCHASE_UNSPECIFIED = 'PurchaseUnspecified', // Unspecified state of the purchase
    RECEIPT_VALIDATION_ERROR = 'ReceiptValidationError', // Receipt validation error
    REMOTE_CONFIGURATION_NOT_AVAILABLE = 'RemoteConfigurationNotAvailable', // Remote configuration is not available for the current user or for the provided context key
    RESPONSE_PARSING_FAILED = 'ResponseParsingFailed', // A problem occurred while serializing or deserializing data
    STORE_PRODUCT_NOT_AVAILABLE = 'StoreProductNotAvailable', // Requested product is not available for purchase or its product id was not found
    UNAUTHORIZED_REQUEST_DATA = 'UnauthorizedRequestData', // App is attempting to use SKPayment's requestData property, but does not have the appropriate entitlement
    UNKNOWN_CLIENT_PLATFORM = 'UnknownClientPlatform', // The current platform is not supported
}


export interface ProductInAppDetails {
    /**
     * The price of the in-app product.
     */
    price: ProductPrice;
}

export interface ProductInstallmentPlanDetails {
    /**
     * Committed payments count after a user signs up for this subscription plan.
     */
    commitmentPaymentsCount: number;

    /**
     * Subsequent committed payments count after this subscription plan renews.
     *
     * Returns 0 if the installment plan doesn't have any subsequent commitment,
     * which means this subscription plan will fall back to a normal
     * non-installment monthly plan when the plan renews.
     */
    subsequentCommitmentPaymentsCount: number;
}


export interface SubscriptionPeriod {
    /**
     * A count of subsequent intervals.
     */
    unitCount: number;

    /**
     * Interval unit.
     */
    unit: SubscriptionPeriodUnit;

    /**
     * ISO 8601 representation of the period, e.g. "P7D", meaning 7 days period.
     */
    iso: string;
}


export interface ProductPrice {
    /**
     * Total amount of money in micro-units,
     * where 1,000,000 micro-units equal one unit of the currency.
     */
    priceAmountMicros: number;
    /**
     * ISO 4217 currency code for price.
     */
    priceCurrencyCode: string;
    /**
     * Formatted price for the payment, including its currency sign.
     */
    formattedPrice: string;
    /**
     * True, if the price is zero. False otherwise.
     */
    isFree: boolean;
    /**
     * Price currency symbol. Null if failed to parse.
     */
    currencySymbol: string | null;
}


export interface ProductPricingPhase {
    /**
     * Price for the current phase.
     */
    price: ProductPrice;

    /**
     * The billing period for which the given price applies.
     */
    billingPeriod: SubscriptionPeriod;

    /**
     * Number of cycles for which the billing period is applied.
     */
    billingCycleCount: number;

    /**
     * Recurrence mode for the pricing phase.
     */
    recurrenceMode: PricingPhaseRecurrenceMode;

    /**
     * Type of the pricing phase.
     */
    type: PricingPhaseType;

    /**
     * True, if the current phase is a trial period. False otherwise.
     */
    isTrial: boolean;

    /**
     * True, if the current phase is an intro period. False otherwise.
     * The intro phase is one of single or recurrent discounted payments.
     */
    isIntro: boolean;

    /**
     * True, if the current phase represents the base plan. False otherwise.
     */
    isBasePlan: boolean;
}


// Common attributes for all entitlements
export interface BaseEntitlement {
    id: string;
}

// Detailed entitlement used by the native app
export interface NativeEntitlement extends BaseEntitlement {
    productId: string;
    renewState: EntitlementRenewState;
    renewsCount: number;
    grantType: EntitlementGrantType;
    transactions: Array<Transaction>;
    expirationDate?: Date;
    trialStartDate?: Date;
    firstPurchaseDate?: Date;
    lastPurchaseDate?: Date;
    autoRenewDisableDate?: Date;
    startedDate: Date;
    isActive: boolean;
    source: EntitlementSource;
    lastActivatedOfferCode?: string;
}

// Web entitlement with fewer attributes


// Union type to handle both Native and Web entitlements
export type Entitlement = NativeEntitlement | WebEntitlement;


export interface ManageEntitlement {
    price: string;
    currency: string;
    nextPaymentDate: number;
    userSinceDate: number;
    active: boolean;
    platform: EntitlementSource;
}




export interface ProductOfferDetails {
    /**
     * The identifier of the current base plan.
     */
    basePlanId: string;

    /**
     * The identifier of the concrete offer, to which these details belong.
     * Null, if these are plain base plan details.
     */
    offerId: string | null;

    /**
     * A token to purchase the current offer.
     */
    offerToken: string;

    /**
     * List of tags set for the current offer.
     */
    tags: string[];

    /**
     * A time-ordered list of pricing phases for the current offer.
     */
    pricingPhases: ProductPricingPhase[];

    /**
     * A base plan phase details.
     */
    basePlan: ProductPricingPhase | null;

    /**
     * Additional details of an installment plan, if exists.
     */
    installmentPlanDetails: ProductInstallmentPlanDetails | null;

    /**
     * A trial phase details, if exists.
     */
    introPhase: ProductPricingPhase | null;

    /**
     * An intro phase details, if exists.
     * The intro phase is one of single or recurrent discounted payments.
     */
    trialPhase: ProductPricingPhase | null;

    /**
     * True, if there is a trial phase in the current offer. False otherwise.
     */
    hasTrial: boolean;

    /**
     * True, if there is any intro phase in the current offer. False otherwise.
     * The intro phase is one of single or recurrent discounted payments.
     */
    hasIntro: boolean;

    /**
     * True, if there is any trial or intro phase in the current offer. False otherwise.
     * The intro phase is one of single or recurrent discounted payments.
     */
    hasTrialOrIntro: boolean;
}

export interface SKProductDiscount {
    price: string;
    localeIdentifier?: string;
    numberOfPeriods: number;
    subscriptionPeriod?: SKSubscriptionPeriod;
    paymentMode: SKProductDiscountPaymentModes;
    identifier?: string;
    type: SKProductDiscountTypes;
    currencySymbol: string;
}

export interface SKSubscriptionPeriod {
    numberOfUnits: number;
    unit: SKPeriodUnits;
}

export interface SKProduct {
    localizedDescription?: string;
    localizedTitle?: string;
    price: string;
    localeIdentifier?: string;
    productIdentifier?: string;
    isDownloadable: boolean;
    downloadContentVersion?: string;
    downloadContentLengths?: Array<number>;
    subscriptionPeriod?: SKSubscriptionPeriod;
    productDiscount?: SKProductDiscount;
    discounts?: Array<SKProductDiscount>;
    subscriptionGroupIdentifier?: string;
    isFamilyShareable?: boolean;
    currencyCode: string;
}


export interface ProductStoreDetails {
    /**
     * Identifier of the base plan to which these details relate.
     * Null for in-app products.
     */
    basePlanId: string | null;

    /**
     * Identifier of the subscription or the in-app product.
     */
    productId: string;

    /**
     * Name of the subscription or the in-app product.
     */
    name: string;

    /**
     * Title of the subscription or the in-app product.
     * The title includes the name of the app.
     */
    title: string;

    /**
     * Description of the subscription or the in-app product.
     */
    description: string;

    /**
     * Offer details for the subscription.
     * Offer details contain all the available variations of purchase offers,
     * including both base plan and eligible base plan + offer combinations
     * from Google Play Console for current {@link basePlanId}.
     * Null for in-app products.
     */
    subscriptionOfferDetails: ProductOfferDetails[] | null;

    /**
     * The most profitable subscription offer for the client in our opinion from all the available offers.
     * We calculate the cheapest price for the client by comparing all the trial or intro phases
     * and the base plan.
     */
    defaultSubscriptionOfferDetails: ProductOfferDetails | null;

    /**
     * Subscription offer details containing only the base plan without any offer.
     */
    basePlanSubscriptionOfferDetails: ProductOfferDetails | null;

    /**
     * Offer details for the in-app product.
     * Null for subscriptions.
     */
    inAppOfferDetails: ProductInAppDetails | null;

    /**
     * True, if there is any eligible offer with a trial
     * for this subscription and base plan combination.
     * False otherwise or for an in-app product.
     */
    hasTrialOffer: boolean;

    /**
     * True, if there is any eligible offer with an intro price
     * for this subscription and base plan combination.
     * False otherwise or for an in-app product.
     */
    hasIntroOffer: boolean;

    /**
     * True, if there is any eligible offer with a trial or an intro price
     * for this subscription and base plan combination.
     * False otherwise or for an in-app product.
     */
    hasTrialOrIntroOffer: boolean;

    /**
     * The calculated type of the current product.
     */
    productType: ProductType;

    /**
     * True, if the product type is InApp.
     */
    isInApp: boolean;

    /**
     * True, if the product type is Subscription.
     */
    isSubscription: boolean;

    /**
     * True, if the subscription product is prepaid, which means that users pay in advance -
     * they will need to make a new payment to extend their plan.
     */
    isPrepaid: boolean;

    /**
     * True, if the subscription product is installment, which means that users commit
     * to pay for a specified amount of periods every month.
     */
    isInstallment: boolean;
}

export interface PurchaseModel {
    productId: string;
    offerId: string | null;
    applyOffer: boolean;
}


export interface QonversionNativeProduct {
    qonversionID: string;
    storeID: string | null;
    basePlanID: string | null;

    /**
     * Google Play Store details of this product.
     * Android only. Null for iOS, or if the product was not found.
     * Doesn't take into account {@link basePlanID}.
     * @deprecated Consider using {@link storeDetails} instead.
     */
    skuDetails: SkuDetails | null;

    /**
     * Google Play Store details of this product.
     * Android only. Null for iOS, or if the product was not found.
     */
    storeDetails: ProductStoreDetails | null;

    /**
     * App store details of this product.
     * iOS only. Null for Android, or if the product was not found.
     */
    skProduct: SKProduct | null;

    offeringId?: string | null;

    /**
     * For Android - the subscription base plan duration. If the {@link basePlanID} is not specified,
     * the duration is calculated using the deprecated {@link skuDetails}.
     * For iOS - the duration of the {@link skProduct}.
     * Null, if it's not a subscription product or the product was not found in the store.
     */
    subscriptionPeriod: SubscriptionPeriod | null;

    /**
     * The subscription trial duration of the default offer for Android or of the product for iOS.
     * See {@link ProductStoreDetails.defaultSubscriptionOfferDetails} for the information on how we
     * choose the default offer for Android.
     * Null, if it's not a subscription product or the product was not found the store.
     */
    trialPeriod: SubscriptionPeriod | null;

    /**
     * The calculated type of this product based on the store information.
     * On Android uses deprecated {@link skuDetails} for the old subscription products
     * where {@link basePlanID} is not specified, and {@link storeDetails} for all the other products.
     * On iOS uses {@link skProduct} information.
     */
    type: ProductType;

    /**
     * Formatted price of for this product, including the currency sign.
     */
    prettyPrice: string | null;

    price?: number;
    currencyCode?: string;
    storeTitle?: string;
    storeDescription?: string;
    prettyIntroductoryPrice?: string;
}

import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivateChild} from '@angular/router';
import { Observable } from 'rxjs';
import { DataStoreService } from "../../services/data-store/data-store.service";
import { HelperService } from "../../services/helper-service/helper.service";

@Injectable({
    providedIn: 'root'
})
export class AccessCodeRequiredGuard implements CanActivate , CanActivateChild{

    constructor(private dataStoreService: DataStoreService, private helperService: HelperService) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {

        // return true to allow user to access the page if access code is not required
        return !this.helperService.userAccessCodeConfirmationRequired(this.dataStoreService.user);
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        return this.canActivate(route, state);
    }
}

import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { HelperService } from 'src/app/services/helper-service/helper.service';

@Component({
    selector: 'app-user-must-update-payment-method-subscription',
    template: `
        <app-blocked-content-modal-template 
            [title]="getTitle()" 
            [description]="getMessage()" 
            [close]="dismiss" 
            [buttonText]="getButtonText()" 
            [buttonAction]="updatePaymentMethod">
        </app-blocked-content-modal-template>
    `
})
export class UserMustUpdatePaymentMethodSubscriptionComponent {
    constructor(
        private modalController: ModalController,
        private helperService: HelperService
    ) {}

    getTitle = () => {
        return 'Payment Method Update Required';
    }

    getMessage = () => {
        return this.helperService.addLineBreaks('Your payment method has failed. Please update your payment details to continue using premium features.');
    }

    getButtonText = () => {
        return 'Update Payment Method';
    }

    dismiss = () => {
        this.modalController.dismiss();
    }

    updatePaymentMethod = async () => {
        await this.helperService.redirectToStripeCustomerPortal();
        this.dismiss();
    }
} 
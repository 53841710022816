import {Component, OnInit} from '@angular/core';
import {QonversionWebService} from '../../../../../services/qonversion/qonversion-web/qonversion-web.service';
import {StripeProduct} from '../../../../../interfaces/stripe-product';
import {StripeService} from '../../../../../services/stripe/stripe.service';
import {HelperService} from '../../../../../services/helper-service/helper.service';
import {environment} from '../../../../../../environments/environment';
import {Router} from '@angular/router';
import {ModalController} from '@ionic/angular';
import { DataStoreService } from '../../../../../services/data-store/data-store.service';
import { UserMustUpdatePaymentMethodSubscriptionComponent } from '../../user-must-update-payment-method-subscription/user-must-update-payment-method-subscription.component';

declare var Stripe: any;

@Component({
    selector: 'app-add-subscription-web-modal',
    template: `
        <div class="modal-container">
            <app-add-subscription-modal 
                [loading]="loading" 
                [products]="stripeProducts" 
                [productSelected]="takeUserToCheckoutScreen">
            </app-add-subscription-modal>
        </div>
    `,
    styles: [`
        .modal-container {
        }
    `]
})
export class AddSubscriptionWebModalComponent implements OnInit {
    stripeProducts: StripeProduct[];
    private stripe: any;
    loading: boolean = true;

    constructor(
        private qonversionWebService: QonversionWebService,
        private stripeService: StripeService,
        private helperService: HelperService,
        private router: Router,
        private modalController: ModalController,
        private dataStore: DataStoreService
    ) {
        this.stripe = Stripe(environment.stripeProjectKey);
    }

    async ngOnInit() {
        if (this.dataStore.user?.userNeedsToUpdatePaymentDetails) {
            await this.showPaymentUpdateModal();
        } else {
            this.getStripeProducts();
        }
    }

    private async showPaymentUpdateModal() {
        await this.modalController.dismiss();
        
        const modal = await this.modalController.create({
            component: UserMustUpdatePaymentMethodSubscriptionComponent,
            cssClass: 'standard-modal'
        });
        
        return await modal.present();
    }

    getStripeProducts() {
        this.stripeService.getStripeProductsOnOffer().subscribe((data) => {
            this.stripeProducts = data;
            this.loading = false;
        }, (error) => {
            this.stripeProducts = [];
            console.log('error', error);
        });
    }

    takeUserToCheckoutScreen(product) {
        this.modalController.dismiss();
        this.router.navigate(['/subscription/add'], {queryParams: {id: product.id}});
    }
}

import {Injectable} from '@angular/core';
import {DataStoreService} from '../data-store/data-store.service';
import {HelperService} from '../helper-service/helper.service';
import {SortNumbersPipe} from '../../pipes/sort-numbers/sort-numbers.pipe';
import {Session} from 'src/app/interfaces/session/session';
import {MetricType} from 'src/app/interfaces/session/metricType';
import {ToBeComparedData} from 'src/app/interfaces/comparison/toBeComparedData';
import {PlayerMetricData} from '../../interfaces/playerMetricData';
import {Metrics} from '../../interfaces/metrics/metrics';
import {MetricDetail} from '../../interfaces/metrics/metric-detail';
import {SessionService} from '../session/session.service';
import {EventType} from '../../interfaces/event-type';
import {SortPipe} from '../../pipes/sort/sort.pipe';
import {UpdatedTPData} from '../../interfaces/session/updatedTPData';
import {SquadPeriodData} from 'src/app/interfaces/squadPeriodData';
import {TranslateService} from '@ngx-translate/core';
import {SessionType} from "../../enums/session-type.enum";
import {ZoneValues} from "../../interfaces/zone-value-interface";
import {ActivatedRoute} from "@angular/router";

@Injectable({
    providedIn: 'root'
})
export class MetricService {

    public lastFetchGetSquadsMetricsMultipleData: { [sessionId: string]: PlayerMetricData[] } = {};

    constructor(public dataStore: DataStoreService, private sortNumbers: SortNumbersPipe, private helperService: HelperService, private sessionService: SessionService, private sort: SortPipe, private translateService: TranslateService, private activeRoute: ActivatedRoute) {
    }

    async setSelectedSessionsMetrics(selectedSessions: Session[], emitSessionEvent = true, useAsync = false) {
        this.dataStore.clearSelectedSessions();
        // Set the isSelected flag on each session
        selectedSessions.forEach(s => s.isSessionSelected = true);
        selectedSessions = this.sort.transform(selectedSessions, 'sessionDate');
        this.dataStore.selectedSessions = selectedSessions;
        const sessionsWithPlayers = selectedSessions.filter(s => s.sessionPlayers && s.sessionPlayers.length > 0);

        if (Array.isArray(sessionsWithPlayers) && sessionsWithPlayers.length > 0) {
            if (Array.isArray(selectedSessions)) {
                if (useAsync) {
                    return await this.getSquadMetricsMultipleAsync(sessionsWithPlayers, emitSessionEvent);
                } else {
                    this.getSquadMetricsMultiple(sessionsWithPlayers, emitSessionEvent);
                }
            }
        } else {
            this.setEmptySessionAndUpdate();
        }
    }

    getSquadMetricsIndividual(session: Session, emitSessionEvent = true) {
        this.dataStore.isFetchingSessionData = true;


        this.sessionService.getSquadMetricsIndividual(session.sessionId)
            .subscribe(data => {
                this.dataStore.playerMetricData = this.setPlayerFromMetricData(data);
                this.dataStore.isFetchingSessionData = false;

                // Emit events to update UI
                if (emitSessionEvent) {
                    this.dataStore.sessionEvent$.next({type: EventType.Selected});
                }
            }, error => {
                this.dataStore.isFetchingSessionData = false;
                // TODO: Deal with error
            });
    }

    getSquadMetricsMultiple(sessions: Session[], emitSessionEvent = true) {
        this.dataStore.isFetchingSessionData = true;
        const playerIds = [];
        sessions.forEach(s => {
            if (Array.isArray(s.sessionPlayers) && s.sessionPlayers.length > 0) {
                s.sessionPlayers.forEach(sp => {
                    const pIndex = playerIds.findIndex(p => p === sp.userId);
                    if (pIndex === -1) {
                        playerIds.push(sp.userId);
                    }
                });
            }
        });

        this.sessionService.getSquadMetricsMultiple(sessions.map(s => s.sessionId), playerIds)
            .subscribe(data => {
                // Store the raw data before processing
                this.lastFetchGetSquadsMetricsMultipleData[sessions.map(s => s.sessionId).join(',')] = this.helperService.getDeepCopyOfArray(data);
                
                // Process the data as before
                this.dataStore.playerMetricData = this.setPlayerFromMetricData(data);
                this.dataStore.isFetchingSessionData = false;

                if (emitSessionEvent) {
                    this.dataStore.sessionEvent$.next({type: EventType.Selected});
                }
            }, error => {
                this.dataStore.isFetchingSessionData = false;
                // TODO: Deal with error
            });
    }

    async getSquadMetricsMultipleAsync(sessions: Session[], emitSessionEvent = true): Promise<PlayerMetricData[]> {
        this.dataStore.isFetchingSessionData = true;
        const playerIds = [];
        sessions.forEach(s => {
            if (Array.isArray(s.sessionPlayers) && s.sessionPlayers.length > 0) {
                s.sessionPlayers.forEach(sp => {
                    const pIndex = playerIds.findIndex(p => p === sp.userId);
                    if (pIndex === -1) {
                        playerIds.push(sp.userId);
                    }
                });
            }
        });

        try {
            const data = await this.sessionService.getSquadMetricsMultiple(sessions.map(s => s.sessionId), playerIds)
                .toPromise();

            // Store the raw data before processing
            this.lastFetchGetSquadsMetricsMultipleData[sessions.map(s => s.sessionId).join(',')] = 
                this.helperService.getDeepCopyOfArray(data);

            // Process the data as before
            this.dataStore.playerMetricData = this.setPlayerFromMetricData(data);
            this.dataStore.isFetchingSessionData = false;

            if (emitSessionEvent) {
                this.dataStore.sessionEvent$.next({type: EventType.Selected});
            }

            return data;
        } catch (error) {
            this.dataStore.isFetchingSessionData = false;
            throw error;
        }
    }

    setEmptySessionAndUpdate() {
        this.dataStore.playerMetricData = [];
        this.dataStore.sessionEvent$.next({type: EventType.Selected});
    }

    setPlayerFromMetricData(playerMetricData: PlayerMetricData[]): PlayerMetricData[] {
        playerMetricData = this.dataStore.removePlayersNoLongerInSquadFromPlayerMetricData(playerMetricData);

        if (playerMetricData && playerMetricData.length > 0) {
            playerMetricData.forEach(pmd => {
                const pIndex = this.dataStore.activeSquad.squadPlayers.findIndex(p => p.playerId === pmd.playerId);
                if (pIndex !== -1) {
                    pmd.player = this.dataStore.activeSquad.squadPlayers[pIndex];
                    // Convert metric values
                    pmd.total = this.convertMetricValues(pmd.total);
                    pmd.firstHalf = this.convertMetricValues(pmd.firstHalf);
                    pmd.secondHalf = this.convertMetricValues(pmd.secondHalf);
                    if (pmd.zoneValues) {
                        pmd.zoneValues = this.convertZoneValuesToUserMetrics(pmd.zoneValues);
                    }
                }

                const sIndex = this.dataStore.selectedSessions.findIndex(s => s.sessionId === pmd.sessionId);

                if (sIndex !== -1) {
                    pmd.session = this.dataStore.selectedSessions[sIndex];
                }
            });
     
            return playerMetricData.filter(pmd => pmd.player !== undefined);
        } else {
            this.dataStore.playerMetricData = [];
        }

        return [];
    }

    convertMetricValues(metrics: Metrics): Metrics {
        if (metrics !== undefined) {
            const userDistanceUnit = this.dataStore.user.coachPreferences.metricUnits.distanceUnit;
            const userSpeedUnit = this.dataStore.user.coachPreferences.metricUnits.speedUnit;

            metrics.totalDistance = this.helperService.convertDistanceByMetricType(metrics.totalDistance, userDistanceUnit, MetricType.totalDistance);
            metrics.maxSpeed = this.helperService.convertSpeed(metrics.maxSpeed, userSpeedUnit);
            metrics.highSpeedRunning = this.helperService.convertDistanceByMetricType(metrics.highSpeedRunning, userDistanceUnit, MetricType.highSpeedRunning);
            metrics.distancePerMinute = this.helperService.convertDistanceByMetricType(metrics.distancePerMinute, userDistanceUnit, MetricType.distancePerMinute);
            metrics.highMetabolicLoadDistance = this.helperService.convertDistanceByMetricType(metrics.highMetabolicLoadDistance, userDistanceUnit, MetricType.highMetabolicLoadDistance);
            metrics.sprintDistance = this.helperService.convertDistanceByMetricType(metrics.sprintDistance, userDistanceUnit, MetricType.sprintDistance);
            metrics.highSpeedRunningPerMinute = this.helperService.convertDistanceByMetricType(metrics.highSpeedRunningPerMinute, userDistanceUnit, MetricType.highSpeedRunningPerMinute);
            metrics.highMetabolicLoadDistancePerMinute = this.helperService.convertDistanceByMetricType(metrics.highMetabolicLoadDistancePerMinute, userDistanceUnit, MetricType.highMetabolicLoadDistancePerMinute);
            metrics.sprintDistancePerMinute = this.helperService.convertDistanceByMetricType(metrics.sprintDistancePerMinute, userDistanceUnit, MetricType.sprintDistancePerMinute);

            return this.roundMetricValues(metrics);
        }
    }


    convertZoneValuesToUserMetrics(zoneMetrics: ZoneValues) {
        const userDistanceUnit = this.dataStore.user.coachPreferences.metricUnits.distanceUnit;

        const zoneAttributes = this.helperService.getDistanceZonesPropertiesArray();
        const gameHalf = this.helperService.getGameHalfPropertiesArray();

        if (zoneMetrics.speedZone) {
            for (const half of gameHalf) {
                for (const attribute of zoneAttributes) {
                    zoneMetrics.speedZone[half][attribute] = this.helperService.convertDistanceByMetricType(zoneMetrics.speedZone[half][attribute], userDistanceUnit, MetricType.totalDistance);
                }
            }
        }
        return zoneMetrics;
    }

    public isPractice() {
        return this.dataStore.selectedSessions.find(s => s.sessionType === SessionType.Practice);
    }

    public getComparisonMetrics(firstComparison: Metrics, secondComparison: Metrics, metricDetail: MetricDetail, isFirstPB = false, isSecondPB = false, firstPlayerId?: string, secondPlayerId?: string, notAbbreviated = false): ToBeComparedData {
        let firstCompareValue = 0;
        let secondCompareValue = 0;
        let firstMetricValue = 0;
        let secondMetricValue = 0;


        if (firstComparison && secondComparison) {
            const areBothZero = firstComparison[metricDetail.type] === 0 && secondComparison[metricDetail.type] === 0;

            if ((firstComparison[metricDetail.type] >= secondComparison[metricDetail.type]) && !areBothZero) {
                firstCompareValue = 100;
                secondCompareValue = this.getComparisonPercentage(firstComparison[metricDetail.type], secondComparison[metricDetail.type]);
            }
            if ((firstComparison[metricDetail.type] <= secondComparison[metricDetail.type]) && !areBothZero) {
                firstCompareValue = this.getComparisonPercentage(secondComparison[metricDetail.type], firstComparison[metricDetail.type]);
                secondCompareValue = 100;
            }

            firstMetricValue = firstComparison[metricDetail.type];
            secondMetricValue = secondComparison[metricDetail.type];
        }
        let metricDisplayName;

        if (notAbbreviated) {
            metricDisplayName = this.translateService.instant(this.helperService.getMetricDisplayText(metricDetail));
        } else {
            metricDisplayName = this.translateService.instant(this.helperService.getMetricDisplayTextAbbreviated(metricDetail));
        }


        const metricUnit = this.helperService.getUnitByMetricType(metricDetail.type, this.dataStore.user.coachPreferences.metricUnits.distanceUnit, this.dataStore.user.coachPreferences.metricUnits.speedUnit);

        if (metricUnit !== undefined && metricUnit.length > 0) {
            metricDisplayName += ` (${metricUnit.toUpperCase()})`;
        }

        const compare: ToBeComparedData = {
            metricDetail,
            metricDisplayName,
            firstMetricValue,
            secondMetricValue,
            firstCompareValue,
            secondCompareValue,
            firstPB: isFirstPB,
            secondPB: isSecondPB
        };

        if (compare.firstPB) {
            const pb = this.dataStore.personalBest.find(p => p.player.playerId === firstPlayerId);

            if (pb && pb[metricDetail.type]) {
                compare.firstPBSession = pb[metricDetail.type].sessionTitle;
                compare.firstPBDate = this.helperService.formatDate(this.dataStore.transformTimestampsToMilliseconds(pb[metricDetail.type].dateRecorded));
            }
        }

        if (compare.secondPB) {
            const pb = this.dataStore.personalBest.find(p => p.player.playerId === secondPlayerId);

            if (pb && pb[metricDetail.type]) {
                compare.secondPBSession = pb[metricDetail.type].sessionTitle;
                compare.secondPBDate = this.helperService.formatDate(this.dataStore.transformTimestampsToMilliseconds(pb[metricDetail.type].dateRecorded));
            }
        }

        return compare;
    }

    public parsePersonalBestData(rawPlayer): any {
        const playerMetricData: PlayerMetricData = {
            player: rawPlayer.player, total: {
                maxSpeed: rawPlayer.maxSpeed && rawPlayer.maxSpeed.metricValue !== undefined ? rawPlayer.maxSpeed.metricValue : 0,
                distancePerMinute: rawPlayer.distancePerMinute && rawPlayer.distancePerMinute.metricValue !== undefined ? rawPlayer.distancePerMinute.metricValue : 0,
                totalDistance: rawPlayer.totalDistance && rawPlayer.totalDistance.metricValue !== undefined ? rawPlayer.totalDistance.metricValue : 0,
                highSpeedRunning: rawPlayer.highSpeedRunning && rawPlayer.highSpeedRunning.metricValue !== undefined ? rawPlayer.highSpeedRunning.metricValue : 0,
                highMetabolicLoadDistance: rawPlayer.highMetabolicLoadDistance && rawPlayer.highMetabolicLoadDistance.metricValue !== undefined ? rawPlayer.highMetabolicLoadDistance.metricValue : 0,
                numberOfSprints: rawPlayer.numberOfSprints && rawPlayer.numberOfSprints.metricValue !== undefined ? rawPlayer.numberOfSprints.metricValue : 0,
                sprintDistance: rawPlayer.sprintDistance && rawPlayer.sprintDistance.metricValue !== undefined ? rawPlayer.sprintDistance.metricValue : 0,
                accelerations: rawPlayer.accelerations && rawPlayer.accelerations.metricValue !== undefined ? rawPlayer.accelerations.metricValue : 0,
                impacts: rawPlayer.impacts && rawPlayer.impacts.metricValue !== undefined ? rawPlayer.impacts.metricValue : 0,
                decelerations: rawPlayer.decelerations && rawPlayer.decelerations.metricValue !== undefined ? rawPlayer.decelerations.metricValue : 0,
                calories: rawPlayer.calories && rawPlayer.calories.metricValue !== undefined ? rawPlayer.calories.metricValue : 0,
                highSpeedRunningPerMinute: rawPlayer.highSpeedRunningPerMinute && rawPlayer.highSpeedRunningPerMinute.metricValue !== undefined ? rawPlayer.highSpeedRunningPerMinute.metricValue : 0,
                highMetabolicLoadDistancePerMinute: rawPlayer.highMetabolicLoadDistancePerMinute && rawPlayer.highMetabolicLoadDistancePerMinute.metricValue !== undefined ? rawPlayer.highMetabolicLoadDistancePerMinute.metricValue : 0,
                sprintDistancePerMinute: rawPlayer.sprintDistancePerMinute && rawPlayer.sprintDistancePerMinute.metricValue !== undefined ? rawPlayer.sprintDistancePerMinute.metricValue : 0,
                stepBalance: rawPlayer.stepBalance && rawPlayer.stepBalance.metricValue !== undefined ? rawPlayer.stepBalance.metricValue : 0,
                dynamicStressLoad: rawPlayer.dynamicStressLoad && rawPlayer.dynamicStressLoad.metricValue !== undefined ? rawPlayer.dynamicStressLoad.metricValue : 0,
                maxHeartRate: rawPlayer.maxHeartRate && rawPlayer.maxHeartRate.metricValue !== undefined ? rawPlayer.maxHeartRate.metricValue : 0,
                averageHeartRate: rawPlayer.averageHeartRate && rawPlayer.averageHeartRate.metricValue !== undefined ? rawPlayer.averageHeartRate.metricValue : 0,
                timeInRedZone: rawPlayer.timeInRedZone && rawPlayer.timeInRedZone.metricValue !== undefined ? rawPlayer.timeInRedZone.metricValue : 0
            }
        };

        playerMetricData.total = this.convertMetricValues(playerMetricData.total);

        return playerMetricData;
    }

    public roundMetricValues(metricValues: Metrics): Metrics {
        // Round metric values
        for (const key of Object.keys(metricValues)) {
            metricValues[key] = this.helperService.roundValueByDecimals(metricValues[key], this.helperService.getDecimalPlacesByMetric(MetricType[key], this.dataStore.user.coachPreferences.metricUnits.distanceUnit));
        }
        return metricValues;
    }

    public getEmptyMetrics(): Metrics {
        return {
            distancePerMinute: 0,
            highSpeedRunning: 0,
            totalDistance: 0,
            maxSpeed: 0,
            highMetabolicLoadDistance: 0,
            numberOfSprints: 0,
            sprintDistance: 0,
            accelerations: 0,
            decelerations: 0,
            calories: 0,
            impacts: 0,
            highSpeedRunningPerMinute: 0,
            highMetabolicLoadDistancePerMinute: 0,
            sprintDistancePerMinute: 0,
            stepBalance: 0,
            dynamicStressLoad: 0,
            maxHeartRate: 0,
            averageHeartRate: 0,
            timeInRedZone: 0
        };
    }

    updatePlayerPBs(session: Session) {
        this.sessionService.getSquadMetricsIndividual(session.sessionId)
            .subscribe(data => {
                if (data && data.length > 0 && session.sessionPlayers !== null && session.sessionPlayers !== undefined) {
                    const updatedPBs: UpdatedTPData[] = [];

                    for (const s of data) {
                        const sIndex = session.sessionPlayers.findIndex(sp => sp.userId === s.playerId);

                        if (sIndex !== -1) {
                            updatedPBs.push({
                                userId: s.playerId,
                                sessionId: session.sessionPlayers[sIndex].sessionId,
                                metrics: s.total
                            });
                        }
                    }

                    if (updatedPBs.length > 0) {
                        this.sessionService.updateSessionCoachMetrics(updatedPBs);
                    }
                }
            });
    }

    private getComparisonPercentage(largestValue: number, lowestValue: number): number {
        return Math.round((lowestValue / largestValue) * 100);
    }
}

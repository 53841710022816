import { trigger, transition, style, animate } from '@angular/animations';

export const slideInAnimation = trigger('routeAnimations', [
  transition('* => *', [
    style({
      position: 'absolute',
      top: 0,
      right: 0,
      width: '100%',
      transform: 'translateX(100%)',
      opacity: 0
    }),
    animate('400ms ease-out', 
      style({
        transform: 'translateX(0)',
        opacity: 1
      })
    )
  ])
]); 
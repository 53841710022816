import {Injectable} from '@angular/core';
import {Router, RouterStateSnapshot} from '@angular/router';

import {Auth} from 'aws-amplify';
import {CognitoUser} from '@aws-amplify/auth';

import {BehaviorSubject} from 'rxjs';
import {map} from 'rxjs/operators';
import {FCMTokenService} from '../fcm-token/fcmtoken.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    authUser: any;
    signedIn: boolean;
    confirmUsername: string;
    userId: string;
    userAuthEvent$ = new BehaviorSubject<boolean>(false);

    constructor(
        public router: Router,
        private fcmToken: FCMTokenService
    ) {
        this.signedIn = false;
    }

    public logout(): void {
        // Go back to the login page
        this.fcmToken.updateUserToken('').subscribe(() => {
            Auth.currentAuthenticatedUser().then(() => {
                Auth.signOut().then(() => {
                    this.userAuthEvent$.next(false);
                    this.clearCurrentUserAndNavigateToWelcome();
                }, () => {
                    this.userAuthEvent$.next(false);
                    this.clearCurrentUserAndNavigateToWelcome();
                });
            }, () => {
                this.userAuthEvent$.next(false);
                this.clearCurrentUserAndNavigateToWelcome();
            });
        });
    }

    public clearCurrentUserAndNavigateToWelcome() {
        this.authUser = null;
        this.userId = '';
        // this.router.navigate(['/welcome']);
        window.location.reload();
    }

    public logoutWithURL(state: RouterStateSnapshot): void {
        Auth.currentAuthenticatedUser().then(() => {
            Auth.signOut().then(() => {
                this.userAuthEvent$.next(false);
                this.router.navigate(['/welcome'], {queryParams: {returnUrl: state.url}});
            });
        }, (error) => {
            this.router.navigate(['/welcome'], {queryParams: {returnUrl: state.url}});
        });
    }

    private logOutWithoutRedirect() {
        return this.fcmToken.updateUserToken('').pipe(map(() => {
            Auth.currentAuthenticatedUser().then(() => {
                Auth.signOut().then(() => {
                });
            });
        }));
    }

    public isAuthenticated(): boolean {
        return this.signedIn;
    }

    public isUserToBeConfirmed(): boolean {
         return this.confirmUsername && this.confirmUsername.length > 0;
    }

    public getCurrentSession() {
        Auth.currentSession()
            .then(data => console.log(data))
            .catch(err => console.log(err));

    }

    // Gives access to the current user id in a more intuitive way
    public getCurrentUserId() {
        if (this.authUser) {
            return this.authUser.username;
        } else {
            return undefined;
        }
    }

    // Deletes user Cognito account
    deleteUserAccount(): Promise<any> {
        return Auth.currentAuthenticatedUser()
            .then((user: CognitoUser) => new Promise<void>((resolve, reject) => {
                user.deleteUser(error => {
                    if (error) {
                        reject(error);
                    } else {
                        // The authStateChanged is not triggered on deleteUser so trigger this manually
                        this.userAuthEvent$.next(false);
                        resolve();
                    }
                });
            }));
    }

    private findCurrentUser() {
        Auth.currentAuthenticatedUser()
            .then(user => {
                    this.signedIn = true;
                    this.authUser = user;
                },
                (error) => {
                }
            );
    }
}

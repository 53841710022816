import {Injectable} from '@angular/core';
import Qonversion, {
    Environment, QonversionConfigBuilder, QonversionInstance, UserPropertiesBuilder, UserPropertyKey
} from '@qonversion/web-sdk';
import {environment} from '../../../../environments/environment';
import {DataStoreService} from '../../data-store/data-store.service';
import {WebEntitlement} from '../../../interfaces/qonversion/web/qonversion-web-entitlements';

@Injectable({
    providedIn: 'root'
})
export class QonversionWebService {
    private qonversionInstance: QonversionInstance;

    constructor(private dataStoreService: DataStoreService) {
    }

    public getInstance(): any {
        return this.qonversionInstance;
    }


    public async getEntitlements(): Promise<WebEntitlement[]> {
        if (!this.qonversionInstance) {
            throw new Error('Qonversion is not initialized.');
        }
        return await this.qonversionInstance.entitlements();
    }

    public async sendStripePurchase(subscriptionId, productId, price, currency) {
        return await this.qonversionInstance.sendStripePurchase({
            currency, price, productId, subscriptionId
        });
    }

    // add a custom user property to the user
    addCustomUserProperty(propertyKey: string, propertyValue: string): void {
        const properties = new UserPropertiesBuilder()
            .setCustomUserProperty(propertyKey, propertyValue)
            .build();

        this.qonversionInstance.setUserProperties(properties);
    }

    getUsersProperties = async () => {
        return await this.qonversionInstance.userProperties();
    }

    logOut = async () => {
        await this.qonversionInstance.logout();
    }

    async doesUserHaveCustomUserIdProperty() {
        if (this.qonversionInstance && await this.qonversionInstance.userProperties()) {
            const userProperties = await this.qonversionInstance.userProperties();
            const definedPropertyWithUserId = userProperties.getDefinedProperty(UserPropertyKey.CustomUserId);
            if (definedPropertyWithUserId && definedPropertyWithUserId.value && definedPropertyWithUserId.value.length > 5 && definedPropertyWithUserId.value === this.dataStoreService.user.coachId) {
                return true;
            }

        }
        return false;
    }

    private async initializeQonversion(): Promise<void> {
        // const config = new QonversionConfigBuilder(environment.qonversionProjectKey).setEnvironment(environment.production ? Environment.Production : Environment.Sandbox).build();
        // this.qonversionInstance = Qonversion.initialize(config);

        // // Identify the user in Qonversion
        // await this.qonversionInstance.identify(this.dataStoreService.user.coachId);

        // // After identifying the user, set the user properties
        // const properties = new UserPropertiesBuilder()
        //     .setCustomUserId(this.dataStoreService.user.coachId)
        //     .setEmail(this.dataStoreService.user.coachEmail)
        //     .setCustomUserProperty('web-used', 'true')
        //     .build();


        // this.qonversionInstance.setUserProperties(properties);
    }
}

import {Component, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import { HelperService } from 'src/app/services/helper-service/helper.service';

@Component({
    selector: 'app-only-editing-current-session-warning', template: `
        <app-blocked-content-modal-template [title]="title" [description]="description" [close]="closeModal" >
        </app-blocked-content-modal-template>
    `, styles: [`
        .only-editing-current-session-warning {
        }
    `]
})
export class OnlyEditingCurrentSessionWarningComponent implements OnInit {

    title: string;
    description: string;
    buttonText: string;
    warningIconClass = 'warning-icon warning-icon-editing-scheduled-session';
    themeColor: string = '--warning-orange';
    constructor(private translate: TranslateService, private modalController: ModalController, private helperService: HelperService) {
        this.title = this.translate.instant('Resources.EditingABulkGamedaySession');
        this.description = this.helperService.addLineBreaks(this.translate.instant('Resources.EditingThisSessionWontAffectFutureSessions'));
    
    }

    ngOnInit() {
    }

    closeModal = () => {
        this.modalController.dismiss();
    }
}

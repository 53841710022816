import {Injectable, NgZone} from '@angular/core';
import {Router} from '@angular/router';
import {Platform} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import { ModalController } from '@ionic/angular';

import {BehaviorSubject, forkJoin, Observable, of, Subject} from 'rxjs';
import {map} from 'rxjs/operators';
import {Subscription} from 'rxjs/internal/Subscription';

import * as moment from 'moment';

import {Squad} from '../../interfaces/squad';
import {CompleteSquad} from 'src/app/interfaces/completeSquad';
import {User} from '../../interfaces/user';
import {AuthService} from '../auth/auth.service';
import {SquadService} from '../squad/squad.service';
import {UserService} from '../user/user.service';
import {Session} from '../../interfaces/session/session';
import {SessionService} from '../session/session.service';
import {Pitch} from '../../interfaces/pitch';
import {PitchService} from '../pitch/pitch.service';
import {FcmService} from '../fcm/fcm.service';
import {Player} from '../../interfaces/player';
import {EventType, SessionEvent} from 'src/app/interfaces/event-type';
import {ComparedPlayer} from 'src/app/interfaces/comparison/comparedPlayer';
import {FCMTokenService} from '../fcm-token/fcmtoken.service';
import {LocalStorageService} from '../local-storage/local-storage.service';
import {MarketingService} from '../marketing/marketing.service';
import {LocalisationService} from '../localisation/localisation.service';
import {Regions} from '../../enums/regions.enum';
import {PlayerMetricData} from '../../interfaces/playerMetricData';
import {HelperService} from '../helper-service/helper.service';
import {BenchmarkPros} from '../../interfaces/benchmark-pros';
import {MetricDetail} from '../../interfaces/metrics/metric-detail';
import {MetricType} from '../../interfaces/session/metricType';
import {MetricUnit} from '../../enums/metric-unit.enum';
import {MetricGroup} from '../../enums/metric-group.enum';
import {ComboChartConfig, ComboChartDBConfig} from '../../interfaces/combo-chart-config';
import {ChartType} from '../../enums/chart-type.enum';
import {ReportTemplateDisplay} from '../../interfaces/report-template';
import {AngularFireAnalytics} from '@angular/fire/compat/analytics';
import {CalendarOptions} from '../../interfaces/calendar-options';
import {CallAvailability} from '../../interfaces/call-availability';
import {ConsultancyCall} from '../../interfaces/consultancy-call/consultancy-call';
import {TimeZone} from '../../interfaces/timezones/time-zones';
import {PlayerComparisonEnum} from '../../enums/player-comparison';
import {Group} from "../../interfaces/groups/group";
import {ScheduledService} from "../scheduled/scheduled.service";
import {SelectedChartTypeEnum} from "../../enums/selected-chart-type-enum";
import {ChartEvent, MetricChartChangeEvent} from "../../interfaces/metric-chart-change-event";
import {SelectedZonesEnum} from "../../enums/selected-zones-enum";
import {HeartRateZoneValues, SpeedZoneValues} from "../../interfaces/zone-value-interface";
import { AddSubscriptionNativeModalComponent } from 'src/app/components/modals/subscription/add-subscription/add-subscription-native-modal/add-subscription-native-modal.component';
import { AddSubscriptionWebModalComponent } from 'src/app/components/modals/subscription/add-subscription/add-subscription-web-modal/add-subscription-web-modal.component';
import { StripeService } from 'src/app/services/stripe/stripe.service';

@Injectable({
    providedIn: 'root'
})
export class DataStoreService {

    
    fetchingSessionData: boolean;
    // Data to store
    user: User;
    squads: CompleteSquad[];
    // never reasign to something else
    activeSquad: CompleteSquad;

    // This should always point to an element from squads
    // Max number of squads
    maxSquads: number;
    // Max number of players
    maxPlayers: number;
    activePitchName: string;
    pitchSearch: string;
    // Templates
    coachTemplates: ReportTemplateDisplay[];
    // Available Consultancy calls
    callAvailability: CallAvailability;
    // Booked Consultancy Calls
    bookedConsultancyCalls: ConsultancyCall[] = [];
    timeZone: TimeZone;
    isDataLoaded: boolean;
    isFetchingData: boolean;
    showSetupError: boolean;
    isFetchingSessionData: boolean = false;
    isFetchingPlayerData: boolean = false;
    isFetchingSquadPeriodData: boolean = false;
    showNewPlayersNotification: boolean;
    numPlayerRequests: number;
    userPreferencesChangeEvent$ = new BehaviorSubject<boolean>(false);
    openSquadPanelEvent$ = new BehaviorSubject<boolean>(true);
    squadChangeEvent$ = new BehaviorSubject<boolean>(false);
    pitchDataChangeEvent$ = new BehaviorSubject<boolean>(false);
    // called when a session is updated or added
    sessionEvent$ = new BehaviorSubject<SessionEvent>({type: EventType.None});
    callEvent$ = new BehaviorSubject<EventType>(EventType.None);
    playerEvent$ = new Subject<EventType>();
    metricChartChangeEvent$ = new Subject<MetricChartChangeEvent | boolean>();
    playerMetricChartChangeEvent$ = new Subject<any>();
    squadPeriodMetricChartChangeEvent$ = new Subject<any>();
    removeMultiplePlayersChangeEvent$ = new Subject<any>();

    // when a group has been deleted or edited that is selected create an event to update the charts
    currentSelectGroupHasBeenUpdatedEvent$ = new Subject<any>();

    // when score is updated when clicking on old session
    scoresUpdatedEvent$: Subject<any> = new Subject<any>();
    // merge session pass merged session and remove old sessions
    mergedSessionRemoveSessionsEvent$: Subject<{ session: Session, removeSessionsIds: string[] }> = new Subject<{
        session: Session, removeSessionsIds: string[]
    }>();
    previousUrl: string;
    navigateToPreviousPage: boolean;
    selectedSessions: Session[] = [];
    selectedPlayer: Player;
    selectedPlayers: Player[] = [];
    selectedCalendarOptions: CalendarOptions;
    selectedMetricChart: MetricDetail;
    selectedPlayerMetricChart: MetricDetail;
    selectedSquadPeriodMetricChart: MetricDetail;
    metricDetails: MetricDetail[];
    squadSessionsFiltered: boolean = false;
    calendarFilteredDataDict = {};


    // Todo - Update variables for player comparison overcomplicated unnecessarily
    comparisonSelectedGroupFP: PlayerComparisonEnum = 0;
    // Save last selected group in Player Comparison Selector Model depending on value of 'First Player'
    comparisonSelectedGroup: PlayerComparisonEnum = 0;

    // Save the PlayerID for when 'First Player' equals true
    comparisonSelectedItemFP: string;
    // Save user's selected item in the selector model
    comparisonSelectedItem: string;

    // Save Enum to differentiate between Squad Players and Top Performers
    comparisonEnum: number;
    // Save Group ID to differentiate between the types of Pro and Academy Players
    comparisonGroupID: string;
    // comparison selected players
    personalBest: ComparedPlayer[] = [];
    academyPlayers: BenchmarkPros[] = [];
    proPlayers: BenchmarkPros[] = [];
    // Cached metrics
    playerMetricData: PlayerMetricData[] = [];
    playerMetricDataFiltered: PlayerMetricData[] = [];
    individualPeriodData: PlayerMetricData[] = [];
    squadPeriodData: PlayerMetricData[] = [];
    multiSessionFilteredData: PlayerMetricData[] = [];
    analysisViewModeSingleOn = true;
    viewCalendarMode: number = 0;
    modalOpen: boolean = false;
    legendGameDayTotalComboEnabled: boolean = true;
    // current selected group Id
    currentSelectedGroup: Group = null;

    // Groups
    // Sub to collect data
    private subscriber: Subscription;

    constructor(public router: Router, private userService: UserService, private squadService: SquadService, private authService: AuthService, private sessionService: SessionService, private pitchService: PitchService, private firebaseNotifications: FcmService, private fcmTokenService: FCMTokenService, private ngZone: NgZone, private platform: Platform, private splashScreen: SplashScreen, private storage: LocalStorageService, private marketing: MarketingService, private localisation: LocalisationService, private helperService: HelperService, private scheduledSessionService: ScheduledService, private angularFireAnalytics: AngularFireAnalytics, private modalController: ModalController, private stripeService: StripeService) {
        this.isDataLoaded = false;
        this.showSetupError = false;
        this.navigateToPreviousPage = false;
        this.fetchingSessionData = false;
        this.coachTemplates = [];

        // Default max squads value is 5 - this will be overwritten by the squad limit in the user profile if it exists
        this.maxSquads = 5;
        this.maxPlayers = 40;


        // wait user to be logged and then get its data.
        this.authService.userAuthEvent$.subscribe((authenticated) => {
            if (authenticated) {
                this.loadData(false);
            } else {
                this.unloadData();
            }
        });
        this.subscribeToChanges();
    }

    // this function is called on page load and also on page refresh.
    async loadData(navigateToPreviousPage: boolean, url?: string) {
        try {
            this.unloadData();
            if (navigateToPreviousPage === true && url.length > 1) {
                this.navigateToPreviousPage = true;
                this.previousUrl = url;
            }
            if (this.authService.getCurrentUserId() !== undefined) {
                this.showSetupPage();
                if (!this.subscriber || this.subscriber.closed === true) {
                    this.isFetchingData = true;
                    this.showSetupError = false;
                    const user = this.authService.authUser.attributes;

                    const data = await this.userService.createUserIfNotPresent(user.name, user.email).toPromise();

                    if (data) {
                        this.user = data;
                    }

                    await this.fetchData();

                    this.angularFireAnalytics.setUserId(this.authService.getCurrentUserId()).then();
                    this.angularFireAnalytics.setUserProperties({
                        coachName: user.name, coachEmail: user.email
                    }).then(() => {
                        return;
                    });

                } else {
                    return;
                }
            } else {
                return;
            }
        } catch (error) {
            return;
        }
    }

    getSyncedPlayers(): Player[] {
 
        if (this.playerMetricData.length > 0) {
            const squadPlayerIdsSet = new Set( this.helperService.getDeepCopyOfArray(this.activeSquad.squadPlayers).map(player => player.playerId));
            return this.playerMetricData.map(pmd => pmd.player && squadPlayerIdsSet.has(pmd.player.playerId) && pmd.player);
        }
        return [];
    }


    showUpgradeToPremiumModal = async () => {
        const modal = await this.modalController.create({
            component: this.platform.is('cordova') ? AddSubscriptionNativeModalComponent : AddSubscriptionWebModalComponent,
            cssClass: 'standard-modal',
            backdropDismiss: true
        });

        modal.onDidDismiss()
            .then((dismiss) => {
                this.modalOpen = false;
            });

        return await modal.present();
    }


    setSelectedPlayerComparisonMetrics() {
        this.metricDetails = this.metricDetails.filter(m => m.group !== MetricGroup.selectedPlayerComparisonMetrics);

        for (const selectedMetricType of this.user.playerComparisonSelectedMetrics) {
            const selectedPlayerComparisonMetric = this.metricDetails.find(metric => metric.type === selectedMetricType);

            if (selectedPlayerComparisonMetric) {
                this.metricDetails.push({
                    ...selectedPlayerComparisonMetric, group: MetricGroup.selectedPlayerComparisonMetrics
                });
            }
        }
    }

    public checkUserSquads() {
        if (this.hasUserAnySquads()) {
            this.setActiveSquad(true).subscribe(() => {
                this.showApp();
                this.isFetchingData = false;
                this.isDataLoaded = true;
            }, error => {
                this.showDataError(error);
            });
        } else {
            this.showSquadSetup();
            this.isFetchingData = false;
            this.isDataLoaded = true;
        }
    }

    public unloadData() {
        this.user = null;
        this.squads = [];
        this.metricDetails = [];
        this.activeSquad = null;

        this.selectedSessions = [];
        this.selectedPlayers = [];

        this.numPlayerRequests = 0;
        this.isDataLoaded = false;
        this.clearChartsData();

        this.coachTemplates = [];
    }

    // Squads management
    public addSquad(squad: Squad) {
        // Change the UI on src squadAvatar update
        if (squad.squadAvatar && squad.squadAvatar.length > 0) {
            squad.squadAvatar = squad.squadAvatar + '?d=' + Date.now();
        }

        const completeSquad = squad as CompleteSquad;
        completeSquad.squadPitches = [];
        completeSquad.squadSessions = [];
        completeSquad.squadLoadedMonths = [];

        this.squads.push(completeSquad);
    }



    isUserAllowedAccessToSquad(squadId: string): boolean {
        const isProSquad = this.user?.coachProSquads?.includes(squadId);
        if (isProSquad) {
            return this.user?.isPro === true;
        }
        return true;
    }

    public addSquads(squads: Squad[]) {
        squads.forEach(squad => {
            this.addSquad(squad);
            // Update player's avatar in case it was updated.
            squad.squadPlayers = this.refreshPlayersAvatars(squad);
        });
    }

    public removePlayersFromSquad(playerIds: string[]) {
        if (this.activeSquad && this.activeSquad.squadPlayers) {
            this.activeSquad.squadPlayers = this.activeSquad.squadPlayers.filter(p => !playerIds.includes(p.playerId));
        }
        this.removeSessionPlayers(this.activeSquad.squadId, playerIds);
        this.playerEvent$.next(EventType.Removed);
    }

    public setActiveSquad(showPlayersNotification?: boolean): Observable<any> {
        if (this.squads && this.squads.length > 0) {
            if (this.squads.findIndex(s => s.squadId === this.user.activeSquadId) !== -1) {
                this.activeSquad = this.squads.find(s => s.squadId === this.user.activeSquadId);
            } else {
                // Update the users active squad
                this.activeSquad = this.squads[0];
                this.user.activeSquadId = this.squads[0].squadId;
                this.userService.updateActiveSquadId(this.squads[0].squadId);
            }
            this.findNewPlayerRequests(showPlayersNotification);
            this.updateDefaultPlayerAvatars();

            // execute just if is the first load of the squad, or squad has no sessions.
            if (!this.hasCompleteSquadData()) {
                return this.completeSquadData();
            }

            this.selectedSessions = [];
            this.clearChartsData();

        } else {
            this.activeSquad = null;
        }

        this.squadChangeEvent$.next(true);
        return of(null);
    }

    public addPitchToActiveSquad(addedPitch: Pitch) {
        this.activeSquad.squadPitches.push(addedPitch);
        this.pitchDataChangeEvent$.next(true);
    }

    public updatePitchById(updatedPitch: Pitch) {
        const pitchIndex = this.activeSquad.squadPitches.findIndex(pitch => pitch.pitchId === updatedPitch.pitchId);

        this.activeSquad.squadPitches[pitchIndex] = updatedPitch;
        this.pitchDataChangeEvent$.next(true);
    }

    public deletePitchById(pitchId: string) {
        const pitchIndex = this.activeSquad.squadPitches.findIndex(pitch => pitch.pitchId === pitchId);

        this.activeSquad.squadPitches.splice(pitchIndex, 1);
        this.pitchDataChangeEvent$.next(true);
    }

    public addCallToCalendar(call: ConsultancyCall) {
        this.bookedConsultancyCalls.push(call);
        this.callEvent$.next(EventType.Added);
    }

    public removeCallFromCalendar(call: ConsultancyCall) {
        const index = this.bookedConsultancyCalls.findIndex(c => c.callId === call.previousCallId);

        if (index !== -1) {
            this.bookedConsultancyCalls.splice(index, 1);
            this.callEvent$.next(EventType.Removed);
        }
    }

    public addSessionToActiveSquad(session: Session) {
        this.activeSquad.squadSessions.push(session);
        this.sessionEvent$.next({type: EventType.Added, session});
    }

    public editSessionForActiveSquad(session: Session) {
        if (!this.isMonthLoaded(session.sessionDate)) {
            const sessionIndex = this.activeSquad.squadSessions.findIndex(s => s.sessionId === session.sessionId);

            if (sessionIndex !== -1) {
                this.sessionEvent$.next({type: EventType.Removed});
            }
        } else {
            this.sessionEvent$.next({type: EventType.Edited});
        }
    }

    public isMonthLoaded(UNIXTime: number) {
        const month = moment(UNIXTime).month();
        const year = moment(UNIXTime).year();

        const foundDates = this.activeSquad.squadLoadedMonths.filter(date => date.month === month && date.year === year);
        return (foundDates && foundDates.length > 0);
    }

    public setMonthLoaded(UNIXTime: number) {
        const month = moment(UNIXTime).month();
        const year = moment(UNIXTime).year();

        this.activeSquad.squadLoadedMonths.push({month, year});
    }

    public transformTimestampsToMilliseconds(timeStamp) {
        if (`${timeStamp}`.length === 10) {
            return timeStamp * 1000;
        } else {
            return timeStamp;
        }
    }

    public loadSessionSyncedPlayers(sessionPlayerId) {
        const syncedPlayerWithoutMetrics: Player = this.activeSquad.squadPlayers.find((squadPlayer) => squadPlayer.playerId === sessionPlayerId);
        if (syncedPlayerWithoutMetrics) {
        }
    }

    public reloadSyncedPlayers() {
        this.selectedSessions[0].sessionPlayers.forEach(player => {
            this.loadSessionSyncedPlayers(player.userId);
        });
    }

    clearChartsData() {
        this.playerMetricData = [];
        this.selectedPlayer = null;
        this.selectedPlayers = [];
    }

    findAdminUserId(): string {
        if (this.activeSquad && this.activeSquad.squadCoaches && this.activeSquad.squadCoaches.length > 0) {
            const cIndex = this.activeSquad.squadCoaches.findIndex(c => c.coachId === this.user.coachId && c.coachRole === 0);

            if (cIndex !== -1) {
                return this.activeSquad.squadCoaches[cIndex].coachId;
            }
        }
        return '';
    }

    isCurrentUserAdmin(): boolean {
        if (this.user) {
            return this.findAdminUserId().toLowerCase() === this.user.coachId.toLowerCase();
        }
        return false;
    }

    // Pitch management

    removePLayersNotification() {
        if (this.showNewPlayersNotification === true) {
            this.showNewPlayersNotification = false;
        }
    }

    getComboChartConfig(comboChartDBConfig: ComboChartDBConfig): ComboChartConfig {
        const comboChartTypes = this.helperService.getComboChartTypes();

        return {
            chartType1: comboChartTypes.find(cc => cc.type === comboChartDBConfig.chartType1),
            chartType2: comboChartTypes.find(cc => cc.type === comboChartDBConfig.chartType2),
            metricDetail1: this.metricDetails.find(md => md.type === comboChartDBConfig.metricType1),
            metricDetail2: this.metricDetails.find(md => md.type === comboChartDBConfig.metricType2)
        };
    }

    updateCountUnitsToOtherUnit(metricDetail) {
        if (metricDetail.comboChartConfig) {
            if (metricDetail.comboChartConfig.metricDetail1.unit === MetricUnit.Count) {
                metricDetail.comboChartConfig.metricDetail1.unit = MetricUnit.Other;
            }
            if (metricDetail.comboChartConfig.metricDetail2.unit === MetricUnit.Count) {
                metricDetail.comboChartConfig.metricDetail2.unit = MetricUnit.Other;
            }
        }
    }

    setSelectedMetricChart(metricDetail: MetricDetail, updateChart?: boolean) {
        this.selectedMetricChart = metricDetail;

        this.updateCountUnitsToOtherUnit(metricDetail);

        if (updateChart) {
            // check if zone or standard chart
            this.metricChartChangeEvent$.next(metricDetail.selectedChartType === SelectedChartTypeEnum.zones ? {chartEvent: ChartEvent.UpdateChartToZone} : true);
        }

    }

    // Call management

    setSelectedPlayerMetricChart(metricDetail: MetricDetail, updateChart?: boolean) {
        this.selectedPlayerMetricChart = metricDetail;

        this.updateCountUnitsToOtherUnit(metricDetail)

        if (updateChart) {
            this.playerMetricChartChangeEvent$.next(true);
        }
    }

    setSelectedSquadPeriodMetricChart(metricDetail: MetricDetail, updateChart?: boolean) {
        this.selectedSquadPeriodMetricChart = metricDetail;

        this.updateCountUnitsToOtherUnit(metricDetail);

        if (updateChart) {
            this.squadPeriodMetricChartChangeEvent$.next(true);
        }
    }

    setSelectedPDFChart(metricDetail: MetricDetail) {
        this.selectedSquadPeriodMetricChart = metricDetail;

        this.updateCountUnitsToOtherUnit(metricDetail);
    }

    // Sessions management

    setDefaultComboCharts() {
        // Set default combo charts for users if none exists
        if (!this.user.coachComboCharts) {
            this.user.coachComboCharts = [];
            this.user.coachComboCharts.push({
                chartType1: ChartType.Bar,
                chartType2: ChartType.Line,
                metricType1: MetricType.totalDistance,
                metricType2: MetricType.maxSpeed
            });

            this.user.coachComboCharts.push({
                chartType1: ChartType.Bar,
                chartType2: ChartType.Line,
                metricType1: MetricType.highSpeedRunning,
                metricType2: MetricType.numberOfSprints
            });
        }
    }


    setPlayerComparisonSelectedMetrics() {
        // Set default selected metrics for users if none exists
        if (!this.user.playerComparisonSelectedMetrics || this.user.playerComparisonSelectedMetrics.length === 0) {
            this.user.playerComparisonSelectedMetrics = [];
            this.user.playerComparisonSelectedMetrics.push(MetricType.totalDistance);
            this.user.playerComparisonSelectedMetrics.push(MetricType.highSpeedRunning);
            this.user.playerComparisonSelectedMetrics.push(MetricType.highMetabolicLoadDistance);
            this.user.playerComparisonSelectedMetrics.push(MetricType.sprintDistance);
            this.user.playerComparisonSelectedMetrics.push(MetricType.calories);
            this.user.playerComparisonSelectedMetrics.push(MetricType.maxSpeed);
            this.user.playerComparisonSelectedMetrics.push(MetricType.accelerations);
            this.user.playerComparisonSelectedMetrics.push(MetricType.decelerations);
            this.user.playerComparisonSelectedMetrics.push(MetricType.distancePerMinute);
            this.user.playerComparisonSelectedMetrics.push(MetricType.sprintDistancePerMinute);
            this.user.playerComparisonSelectedMetrics.push(MetricType.highMetabolicLoadDistancePerMinute);
            this.user.playerComparisonSelectedMetrics.push(MetricType.impacts);
            this.user.playerComparisonSelectedMetrics.push(MetricType.numberOfSprints);
            this.user.playerComparisonSelectedMetrics.push(MetricType.highSpeedRunningPerMinute)
        }
    }

    clearSelectedSessions() {
        this.selectedSessions.forEach(s => s.isSessionSelected = false);
        this.selectedSessions = [];
    }

    setSelectedGroup() {
        if (this.currentSelectedGroup && this.activeSquad.squadGroups && this.activeSquad.squadGroups.length && this.activeSquad.squadGroups.length > 0 && this.activeSquad.squadGroups.find(g => g.groupId === this.currentSelectedGroup.groupId)) {
            return;
        }

        this.currentSelectedGroup = this.activeSquad && this.activeSquad.squadGroups && this.activeSquad.squadGroups.length > 0 && this.activeSquad.squadGroups[0] || null;
    }

    isScheduledPracticeSessionDateBeforeNextSessionToAddDate(practiceSessionScheduleId: string, sessionDate: number) {
        const currentPracticeSessionScheduledObject = this.activeSquad.squadScheduledPracticeSessions.find(s => s.sessionScheduleId === practiceSessionScheduleId);


        if (currentPracticeSessionScheduledObject) {

            const roundedSessionDate = moment(sessionDate).startOf('second');
            const roundedNextSessionToAddDate = moment(currentPracticeSessionScheduledObject.nextSessionToAddDate).startOf('second');

            if (roundedSessionDate.isSameOrAfter(roundedNextSessionToAddDate)) {
                return false;
            }
        }

        return true;
    }

    async fetchUpdatedScheduledPracticeSessionsForSquad() {
        this.scheduledSessionService.getPracticeScheduledSessionsBySquadId(this.activeSquad.squadId)
            .subscribe((data) => {
                this.activeSquad.squadScheduledPracticeSessions = data;
                this.sessionEvent$.next({type: EventType.Added, practiceSessionScheduleId: 'update'});
            }, (error) => {
                console.error('Error fetching practice scheduled sessions:', error);
            });
    }

    removeSessionFromScheduledPracticeSession(session: Session, scheduleSessionId: string) {
        const scheduledSession = this.activeSquad.squadScheduledPracticeSessions.find(s => s.sessionScheduleId === scheduleSessionId);
        scheduledSession.datesToExclude.push(session.sessionStartTime);
        this.scheduledSessionService.updatePracticeScheduledSession(scheduledSession);

        // remove element
        const index = this.activeSquad.squadScheduledPracticeSessions.findIndex(s => s.sessionScheduleId === scheduleSessionId);
        this.activeSquad.squadScheduledPracticeSessions[index] = scheduledSession;
        this.sessionEvent$.next({type: EventType.Removed, session});
    }

    addPracticeScheduledSession = (scheduledSession) => {
        // remove practice session schedule parent
        const newSession = {
            ...scheduledSession, practiceSessionScheduleId: null
        };

        if (newSession.practiceSessionScheduleId === null) {
            delete newSession.practiceSessionScheduleId;
        }

        this.sessionService.createSession(newSession).subscribe((session: Session) => {
            this.addSessionToActiveSquad(session);
        }, () => {
        });
    }

    removePlayersNoLongerInSquadFromPlayerMetricData(playerMetricData: PlayerMetricData[]) {
        const squadPlayerIdsSet = new Set(this.activeSquad.squadPlayers.map(player => player.playerId));
        return playerMetricData.filter(p => p && squadPlayerIdsSet.has(p.playerId));
    }

    updateCurrentSquadZonalIntervals(selectedZonesEnum: SelectedZonesEnum, zoneIntervalsValues: SpeedZoneValues | HeartRateZoneValues) {
        if (selectedZonesEnum === SelectedZonesEnum.SPEED) {
            this.activeSquad.squadSetZoneIntervals.speedZone = (zoneIntervalsValues as SpeedZoneValues);
        } else if (selectedZonesEnum === SelectedZonesEnum.HEARTRATE) {
            this.activeSquad.squadSetZoneIntervals.heartRateZone = (zoneIntervalsValues as HeartRateZoneValues);
        }
    }

    async fetchData() {
        try {
            const squadList = await this.squadService.getAllByCoachId(this.authService.getCurrentUserId()).toPromise();
            // Set squad limit if it exists
            if (this.user.coachSquadLimit) {
                this.maxSquads = this.user.coachSquadLimit;
            }

            this.user.isPro = this.user.coachProUser;

            this.marketing.subscribeUserToList(this.user);
            this.squads = [];
            this.addSquads(squadList);
            this.populateMetricDetails();

            // notification implementation
            await this.platform.ready();

            this.saveCoachPushToken();
            this.subscribeToPlayersOnSquadLeave();

            if (this.helperService.userAccessCodeConfirmationRequired(this.user)) {
                this.router.navigate(['/user-confirmation-squad-access-code']);
                this.isFetchingData = false;
            } else {
                const firstTime = await this.storage.getFirstTimeFlag(this.user.coachId);
                if (firstTime) {
                    this.updateUserPreferencesByRegion();
                    this.showUserTutorial();
                    this.isFetchingData = false;
                } else {
                    this.checkUserSquads();
                }
                this.setSelectedGroup();
            }
            this.isDataLoaded = true;
        } catch (error) {
            this.showDataError(error);
        }
    }

    userAllowedToAddAnotherSquad() {
        return (this.squads?.length < this.maxSquads) || (this.user.isPro && this.squads.length < 6);
    }

    getMaxSquadsCount() {
        if (this.user.isPro) {
            return 6;
        } else {
            return this.maxSquads;
        }
    }

    private showSetupPage() {
        if (this.router.url.indexOf('setup') === -1) {
            this.router.navigate(['/setup']).then();
        }
    }

    private showApp() {
        if (this.router.url.indexOf('tabs') === -1) {
            if (this.navigateToPreviousPage === true && this.previousUrl && this.previousUrl.indexOf('setup') === -1) {
                this.router.navigate([this.previousUrl]).then();
            } else {
                this.router.navigate(['/tabs']).then();
            }
        }
    }

    private showUserTutorial() {
        if (this.router.url.indexOf('setup/userTutorial') === -1) {
            this.router.navigate(['/setup/userTutorial']);
        }
    }

    private showSquadSetup() {
        if (this.router.url.indexOf('setup/joinSquad') === -1) {
            this.router.navigate(['/setup/joinSquad']).then();
        }
    }

    private showDataError(err?: any) {
        this.showSetupError = true;
        this.isDataLoaded = false;
        this.isFetchingData = false;
    }

    private saveCoachPushToken() {
        if (this.platform.is('cordova')) {
            this.firebaseNotifications.getToken().then(token => {
                this.fcmTokenService.updateUserToken(token).subscribe();
            });

            this.firebaseNotifications.subscribeToTokenChange().subscribe((token) => {
                this.fcmTokenService.updateUserToken(token).subscribe();
            });
        }
    }

    private subscribeToPlayersOnSquadLeave() {
        if (this.platform.is('cordova')) {
            this.firebaseNotifications.subscribeToNotifications().subscribe(() => {
                this.userService.getLastNotification().subscribe((notification) => {
                    const recivedSquadId = notification.squadId;
                    const recivedPlayerId = notification.playerId;

                    const squadIndex = this.squads.findIndex(squad => squad.squadId === recivedSquadId);
                    const playerIndex = this.squads[squadIndex].squadPlayers.findIndex(player => player.playerId === recivedPlayerId);

                    this.ngZone.run(() => {
                        this.squads[squadIndex].squadPlayers.splice(playerIndex, 1);
                        this.removeSessionPlayers(recivedSquadId, [recivedPlayerId]);
                    });

                    this.userService.deleteAllNotifications().subscribe();
                });
            });
        }
    }

    private completeSquadData(): Observable<any> {
        return this.getAdditionalSquadData(this.activeSquad.squadId).pipe(map(response => {
            if (response && response.length > 1) {
                this.activeSquad.squadSessions = [...response[0]];
                if (!this.activeSquad.squadSessions || this.activeSquad.squadSessions.length === 0) {
                    this.selectedSessions = [];
                    this.clearChartsData();
                }
                this.activeSquad.squadPitches = [...response[1]];
                this.activeSquad.squadScheduledPracticeSessions = [...response[2]];
                this.pitchDataChangeEvent$.next(true);
                this.squadChangeEvent$.next(true);
            }
        }));
    }

    private hasCompleteSquadData() {
        return (this.activeSquad.squadLoadedMonths && this.activeSquad.squadLoadedMonths.length > 0);
    }

    private hasUserAnySquads() {
        return (this.squads && this.squads.length > 0);
    }

    private getAdditionalSquadData(squadId: string): Observable<any[]> {
        this.activeSquad.squadSessions = []; // Clear squad sessions since we are getting all sessions now

        const startPrevMonth = moment().set('year', 2018).startOf('month').valueOf();
        // const startPrevMonth = moment().subtract(1, 'months').startOf('month').valueOf();
        const endNextMonth = moment().add(1, 'months').endOf('month').valueOf();

        this.setMonthLoaded(startPrevMonth);
        this.setMonthLoaded(moment().valueOf());
        this.setMonthLoaded(endNextMonth);

        const squadSessions = this.sessionService.getSessionsByDate(squadId, startPrevMonth, endNextMonth);
        const squadPitches = this.pitchService.getBySquadId(squadId);
        const squadScheduledPracticeSessions = this.scheduledSessionService.getPracticeScheduledSessionsBySquadId(squadId);

        return forkJoin([squadSessions, squadPitches, squadScheduledPracticeSessions]);
    }

    private findNewPlayerRequests(showPlayersNotification?: boolean) {
        this.showNewPlayersNotification = false;
        this.numPlayerRequests = 0;

        // Only show notification to admin users
        if (this.findAdminUserId().toLowerCase() === this.user.coachId.toLowerCase() && showPlayersNotification && showPlayersNotification === true && this.activeSquad && this.activeSquad.squadPlayers.length > 0) {
            const pendingPlayers = this.activeSquad.squadPlayers.filter(p => p.playerInviteAccepted === false);

            if (pendingPlayers.length > 0) {
                this.showNewPlayersNotification = true;
                this.numPlayerRequests = pendingPlayers.length;
            }
        }
    }

    private updateDefaultPlayerAvatars() {
        // Update players which have no avatar set
        // convert players name so its in the right format as some will be uppercase and lowercase
        const defaultAvatar = 'https://s3-eu-west-1.amazonaws.com/athlete-series-media-bucket-live-production/default/genericProfileImage/default.png';
        if (this.activeSquad.squadPlayers && this.activeSquad.squadPlayers.length > 0) {
            this.activeSquad.squadPlayers.forEach(p => {
                p.playerName = this.helperService.translateToStandardNameFormat(p.playerName);

                if (!p.playerAvatar) {
                    p.playerAvatar = defaultAvatar;
                } else {
                    this.helperService.checkImageExists(p.playerAvatar)
                        .then(data => {
                            if (!data) {
                                p.playerAvatar = defaultAvatar;
                            }
                        });
                }
            });
        }
    }

    private refreshPlayersAvatars(squad: Squad) {
        if (squad.squadPlayers) {
            return squad.squadPlayers.map(player => {
                if (player.playerAvatar) {
                    player.playerAvatar = player.playerAvatar + '?d=' + Date.now();
                }
                return player;
            });
        } else {
            return [];
        }
    }

    private removeSessionPlayers(squadId: string, playerIds: string[]) {
        if (this.activeSquad && squadId === this.activeSquad.squadId && this.selectedSessions[0] && this.selectedSessions[0].sessionPlayers) {
            let updateSession = false;

            playerIds.forEach(pId => {
                const playerIndex = this.selectedSessions[0].sessionPlayers.findIndex(player => player.userId === pId);
                if (playerIndex !== -1) {
                    this.selectedSessions[0].sessionPlayers.splice(playerIndex, 1);
                    updateSession = true;
                }
            });

            if (updateSession) {
                this.reloadSyncedPlayers();
                this.sessionEvent$.next({type: EventType.Edited});
            }
        }
    }

    private subscribeToChanges() {
    }

    private updateUserPreferencesByRegion() {
        if (this.localisation.currentUserRegion === Regions.NorthAmerica) {
            this.user.coachPreferences.metricUnits.speedUnit = 2;
            this.user.coachPreferences.metricUnits.distanceUnit = 2;
            this.userService.updateUserData(this.user).subscribe();
        }
    }

    private populateMetricDetails() {
        this.metricDetails = [];
        // this.metricDetails.push({
        //     name: this.helperService.getTransByKey('Metrics.TotalDistance'),
        //     pro: false,
        //     unit: MetricUnit.Distance,
        //     type: MetricType.totalDistance,
        //     group: MetricGroup.Custom,
        //     selectedChartType: SelectedChartTypeEnum.default
        // });
        this.metricDetails.push({
            name: this.helperService.getTransByKey('Metrics.TotalDistance'),
            pro: false,
            unit: MetricUnit.Distance,
            type: MetricType.totalDistance,
            group: MetricGroup.Volume,
            selectedChartType: SelectedChartTypeEnum.default
        });

        this.metricDetails.push({
            name: this.helperService.getTransByKey('Metrics.HSR'),
            pro: false,
            unit: MetricUnit.Distance,
            type: MetricType.highSpeedRunning,
            group: MetricGroup.Volume,
            selectedChartType: SelectedChartTypeEnum.default
        });

        this.metricDetails.push({
            name: this.helperService.getTransByKey('Metrics.DistPerMin'),
            pro: false,
            unit: MetricUnit.Distance,
            type: MetricType.distancePerMinute,
            group: MetricGroup.Intensity,
            selectedChartType: SelectedChartTypeEnum.default
        });


        // this.metricDetails.push({
        //     name: this.helperService.getTransByKey('Metrics.DistPerMin'),
        //     pro: false,
        //     unit: MetricUnit.Distance,
        //     type: MetricType.distancePerMinute,
        //     group: MetricGroup.Custom,
        //     selectedChartType: SelectedChartTypeEnum.default
        // });
        //
        // this.metricDetails.push({
        //     name: this.helperService.getTransByKey('Metrics.Impacts'),
        //     pro: false,
        //     unit: MetricUnit.Other,
        //     type: MetricType.impacts,
        //     group: MetricGroup.Custom,
        //     selectedChartType: SelectedChartTypeEnum.default
        // });
        this.metricDetails.push({
            name: this.helperService.getTransByKey('Metrics.Impacts'),
            pro: false,
            unit: MetricUnit.Other,
            type: MetricType.impacts,
            group: MetricGroup.Stress,
            selectedChartType: SelectedChartTypeEnum.default
        });

        // this.metricDetails.push({
        //     name: this.helperService.getTransByKey('Metrics.HSR'),
        //     pro: false,
        //     unit: MetricUnit.Distance,
        //     type: MetricType.highSpeedRunning,
        //     group: MetricGroup.Custom,
        //     selectedChartType: SelectedChartTypeEnum.default
        // });

        this.metricDetails.push({
            name: this.helperService.getTransByKey('Metrics.MaxSpeed'),
            pro: false,
            unit: MetricUnit.Speed,
            type: MetricType.maxSpeed,
            group: MetricGroup.Speed,
            selectedChartType: SelectedChartTypeEnum.default
        });
        this.metricDetails.push({
            name: this.helperService.getTransByKey('Metrics.HMLD'),
            pro: false,
            unit: MetricUnit.Distance,
            type: MetricType.highMetabolicLoadDistance,
            group: MetricGroup.Volume,
            selectedChartType: SelectedChartTypeEnum.default
        });
        // this.metricDetails.push({
        //     name: this.helperService.getTransByKey('Metrics.HMLD'),
        //     pro: false,
        //     unit: MetricUnit.Distance,
        //     type: MetricType.highMetabolicLoadDistance,
        //     group: MetricGroup.Custom,
        //     selectedChartType: SelectedChartTypeEnum.default
        // });

        this.metricDetails.push({
            name: this.helperService.getTransByKey('Metrics.NumberOfSprints'),
            pro: false,
            unit: MetricUnit.Count,
            type: MetricType.numberOfSprints,
            group: MetricGroup.Speed,
            selectedChartType: SelectedChartTypeEnum.default
        });
        this.metricDetails.push({
            name: this.helperService.getTransByKey('Metrics.SprintDistance'),
            pro: false,
            unit: MetricUnit.Distance,
            type: MetricType.sprintDistance,
            group: MetricGroup.Volume,
            selectedChartType: SelectedChartTypeEnum.default
        });
        this.metricDetails.push({
            name: this.helperService.getTransByKey('Metrics.Accelerations'),
            pro: false,
            unit: MetricUnit.Count,
            type: MetricType.accelerations,
            group: MetricGroup.Speed,
            selectedChartType: SelectedChartTypeEnum.default
        });
        this.metricDetails.push({
            name: this.helperService.getTransByKey('Metrics.Decelerations'),
            pro: false,
            unit: MetricUnit.Count,
            type: MetricType.decelerations,
            group: MetricGroup.Speed,
            selectedChartType: SelectedChartTypeEnum.default
        });
        this.metricDetails.push({
            name: this.helperService.getTransByKey('Metrics.Calories'),
            pro: false,
            unit: MetricUnit.Count,
            type: MetricType.calories,
            group: MetricGroup.Volume,
            selectedChartType: SelectedChartTypeEnum.default
        });
        this.metricDetails.push({
            name: this.helperService.getTransByKey('Metrics.HSRPerMin'),
            pro: false,
            unit: MetricUnit.Distance,
            type: MetricType.highSpeedRunningPerMinute,
            group: MetricGroup.Intensity,
            selectedChartType: SelectedChartTypeEnum.default
        });
        this.metricDetails.push({
            name: this.helperService.getTransByKey('Metrics.HMLDPerMin'),
            pro: false,
            unit: MetricUnit.Distance,
            type: MetricType.highMetabolicLoadDistancePerMinute,
            group: MetricGroup.Intensity,
            selectedChartType: SelectedChartTypeEnum.default
        });
        this.metricDetails.push({
            name: this.helperService.getTransByKey('Metrics.SprintDistancePerMin'),
            pro: false,
            unit: MetricUnit.Distance,
            type: MetricType.sprintDistancePerMinute,
            group: MetricGroup.Intensity,
            selectedChartType: SelectedChartTypeEnum.default
        });
        this.metricDetails.push({
            name: this.helperService.getTransByKey('Metrics.StepBalance'),
            pro: false,
            unit: MetricUnit.Other,
            type: MetricType.stepBalance,
            group: MetricGroup.Stress,
            selectedChartType: SelectedChartTypeEnum.default
        });
        this.metricDetails.push({
            name: this.helperService.getTransByKey('Metrics.DSL'),
            pro: false,
            unit: MetricUnit.Other,
            type: MetricType.dynamicStressLoad,
            group: MetricGroup.Stress,
            selectedChartType: SelectedChartTypeEnum.default
        });
        this.metricDetails.push({
            name: this.helperService.getTransByKey('Metrics.MaxHR'),
            pro: false,
            unit: MetricUnit.HeartRate,
            type: MetricType.maxHeartRate,
            group: MetricGroup.HeartRate,
            selectedChartType: SelectedChartTypeEnum.default
        });
        this.metricDetails.push({
            name: this.helperService.getTransByKey('Metrics.AvgHR'),
            pro: false,
            unit: MetricUnit.HeartRate,
            type: MetricType.averageHeartRate,
            group: MetricGroup.HeartRate,
            selectedChartType: SelectedChartTypeEnum.default
        });
        this.metricDetails.push({
            name: this.helperService.getTransByKey('Metrics.TimeInRedZone'),
            pro: false,
            unit: MetricUnit.HeartRate,
            type: MetricType.timeInRedZone,
            group: MetricGroup.HeartRate,
            selectedChartType: SelectedChartTypeEnum.default
        });

        // push the metrics for the zonalCharts
        this.metricDetails.push({
            name: this.helperService.getTransByKey('Resources.SpeedZones'),
            group: undefined,
            pro: false,
            unit: undefined,
            zoneType: SelectedZonesEnum.SPEED,
            selectedChartType: SelectedChartTypeEnum.zones
        });

        this.metricDetails.push({
            name: this.helperService.getTransByKey('Resources.HeartRateZones'),
            group: undefined,
            pro: false,
            unit: undefined,
            zoneType: SelectedZonesEnum.HEARTRATE,
            selectedChartType: SelectedChartTypeEnum.zones
        });

        if (!this.user.coachComboCharts || !this.user.playerComparisonSelectedMetrics) {
            this.setDefaultComboCharts();
            this.setPlayerComparisonSelectedMetrics();
            this.userService.updateUserData(this.user).subscribe();
        }


// add player comparison selected metrics to the metric details
        this.setSelectedPlayerComparisonMetrics();

        // Todo: Find issue causing data having to be parsed sometimes

        // parses the data if it's in a string format
        if (typeof this.user.coachComboCharts === 'string') {
            this.user.coachComboCharts = JSON.parse(this.user.coachComboCharts);
        }

        if (typeof this.user.playerComparisonSelectedMetrics === 'string') {
            this.user.playerComparisonSelectedMetrics = JSON.parse(this.user.playerComparisonSelectedMetrics);
        }

        // Add combo chart metrics
        if (this.user.coachComboCharts && this.user.coachComboCharts.length > 0) {

            this.user.coachComboCharts.forEach(c => {
                this.metricDetails.push({
                    group: MetricGroup.Combo,
                    name: this.metricDetails.find(md => md.type === c.metricType1).name + ' / ' + this.metricDetails.find(md => md.type === c.metricType2).name,
                    pro: true,
                    unit: MetricUnit.Other,
                    selectedChartType: SelectedChartTypeEnum.combo,
                    comboChartDBConfig: c,
                    comboChartConfig: this.getComboChartConfig(c)
                });
            });
        }

        // if user has time in red zone remove it from their profile.

        // Set default metric chart selections
        this.setSelectedMetricChart(this.metricDetails[0]);
        this.setSelectedPlayerMetricChart(this.metricDetails[0]);
        this.setSelectedSquadPeriodMetricChart(this.metricDetails[0]);
        this.setSelectedPDFChart(this.metricDetails[0]);
    }

    /**
     * Checks if user has permission to access a specific session
     * @param session The session to check access for
     * @returns boolean indicating if user has access
     */
    isUserAllowedAccessToSession(session: Session, lastHadAccessToPremiumnFeaturesTimestamp?: number): boolean {
        // If user is currently pro, they have access to everything
        if (this.user?.isPro) {
            return true;
        }
        
        // If it's a pro session
        if (this.helperService.isProSession(session)) {
            if (!lastHadAccessToPremiumnFeaturesTimestamp) {
                return false;
            }

            const sessionDate = moment(session?.sessionDate);
            const lastPremiumAccess = moment(lastHadAccessToPremiumnFeaturesTimestamp);
            
            if (sessionDate.isAfter(lastPremiumAccess)) {
                return false;
            }
        }

        return true;
    }

 
}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class InternalCoachManagementService {
  constructor() { }

  // Example method to get coaches
  getCoaches() {
    // Logic to retrieve coaches
  }

  // Example method to add a coach
  addCoach(coach: any) {
    // Logic to add a new coach
  }

  // Example method to remove a coach
  removeCoach(coachId: string) {
    // Logic to remove a coach
  }
} 
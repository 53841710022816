import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {DataStoreService} from '../../data-store/data-store.service';
import {NativeEntitlement, QonversionNativeProduct} from '../../../interfaces/qonversion-native-product';
import {UserPropertyKey} from '../../../enums/qonversion-enums';
@Injectable({
    providedIn: 'root',
})
export class QonversionNativeService {
    private Qonversion: any;
    private initializationPromise: Promise<void>;
    private isInitialized: boolean = false;

    constructor(private dataStoreService: DataStoreService) {
    }

    deepCopyWithMethods(obj) {
        return Object.keys(obj).reduce((acc, key) => {
            acc[key] = typeof obj[key] === 'object' ? this.deepCopyWithMethods(obj[key]) : obj[key];
            return acc;
        }, Array.isArray(obj) ? [] : {});
    }

    public async getProductsEnsureInitialized(): Promise<QonversionNativeProduct[]> {
        try {
            await this.ensureInitialized();
            if (!this.Qonversion) {
                console.error('Qonversion plugin is still not available after initialization.');
                return [];
            }

            const productsMap = await this.Qonversion.getSharedInstance().products();

            return Array.from(productsMap.values());
        } catch (error) {
            console.error('Error fetching products:', error);
            return [];
        }
    }

    public async getEntitlementsEnsureInitialized(): Promise<NativeEntitlement[]> {
        try {
            await this.ensureInitialized();

            if (!this.Qonversion) {
                console.error('Qonversion plugin is still not available after initialization.');
                return [];
            }

            const entitlements = await this.Qonversion.getSharedInstance().checkEntitlements();
            // alert('entitlements')
            // alert(JSON.stringify(entitlements))

            return Array.from(entitlements.values());

        } catch (error) {
            console.error('Error fetching entitlements:', error);
            alert('Error fetching entitlements: ' + error.message);
            return [];
        }
    }

    async purchaseProduct(product) {
        try {
            const purchaseModel = product.toPurchaseModel(); // Convert product to purchase model


            // Perform the purchase using Qonversion SDK
            const entitlements = await this.Qonversion.getSharedInstance().purchase(purchaseModel);

            // Convert Map to an object or array
            const entitlementsObj = Object.fromEntries(entitlements);

            if (entitlements) {
            }
        } catch (e) {
            if (e.userCanceled) {
                // User canceled the purchase
            } else {
                // Log any other errors
                console.log('Purchase error: ', e);
            }
        }
    }

    async logOut() {
        await this.Qonversion.getSharedInstance().logout();
    }

    private initialize(): Promise<void> {
        return new Promise((resolve, reject) => {
            document.addEventListener('deviceready', async () => {
                try {
                    // @ts-ignore
                    this.Qonversion = window.Qonversion;
                    if (!this.Qonversion) {
                        console.error('Qonversion plugin is not available.');
                        return reject(new Error('Qonversion plugin is not available.'));
                    }

                    const {LaunchMode, Environment, EntitlementsCacheLifetime} = this.Qonversion;

                    // const config = new this.Qonversion.ConfigBuilder(environment.qonversionProjectKey, LaunchMode.SUBSCRIPTION_MANAGEMENT)
                    //     .setEnvironment(environment.production ? Environment.PRODUCTION : Environment.SANDBOX)
                    //     .setEntitlementsCacheLifetime(EntitlementsCacheLifetime.MONTH)
                    //     .setEntitlementsUpdateListener({
                    //         onEntitlementsUpdated: (entitlements) => {
                    //         },
                    //     })
                    //     .build();

                    // this.Qonversion.initialize(config);
                    // await this.Qonversion.getSharedInstance().identify(this.dataStoreService.user.coachId);


                    // this.isInitialized = true;
                    resolve();
                } catch (error) {
                    console.error('Error initializing Qonversion:', error);
                    reject(error);
                }
            }, false);
        });
    }

    private async ensureInitialized(): Promise<void> {
        if (this.isInitialized) {
            return;
        }
        await this.initializationPromise;
        if (!this.isInitialized) {
            throw new Error('Qonversion failed to initialize.');
        }
    }

    public reinitialize(): Promise<void> {
        this.isInitialized = false;

        return this.initialize()
            .then(() => {
                alert("Reinitialized Qonversion successfully. dffgdfg");
            })
            .catch((error) => {
                console.error("Reinitialization failed:", error);
            });
    }


    async doesUserHaveCustomUserIdProperty() {
        const userProperties = await this.Qonversion.getSharedInstance().userProperties();
        const definedPropertyWithUserId = userProperties.getDefinedProperty(UserPropertyKey.CUSTOM_USER_ID);
        if (definedPropertyWithUserId && definedPropertyWithUserId.value && definedPropertyWithUserId.value.length > 5 && definedPropertyWithUserId.value === this.dataStoreService.user.coachId) {
            return true;
        }

        return false;
    }

}

import 'hammerjs';
import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

import {Amplify, Auth} from 'aws-amplify';

import * as moment from 'moment';
import 'hammerjs';

let serverURL = environment.awsEUServer;

// Set the production server url
if (environment.production) {
    if (moment().utcOffset() >= 0) { // EU
        serverURL = environment.awsEUServer;
    } else { // US
        serverURL = environment.awsUSServer;
    }
}

Amplify.configure({
    Auth: {
        region: environment.awsRegion,
        userPoolId: environment.awsUserPoolId,
        userPoolWebClientId: environment.awsUserPoolWebClientId
    },
    API: {
        endpoints: [
            {
                name: environment.awsAPIName,
                endpoint: serverURL,
                custom_header: async () => {
                    const user = await Auth.currentAuthenticatedUser();
                    return {Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`};
                }
            }
        ]
    }
});


if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.log(err));

import {Component} from '@angular/core';
import {BreakpointObserver, BreakpointState} from '@angular/cdk/layout';

import {NavController, Platform} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {ScreenOrientation} from '@ionic-native/screen-orientation/ngx';
import { register } from 'swiper/element/bundle';

import {LocationService} from './services/location/location.service';
import {LocalisationService} from './services/localisation/localisation.service';
import {Keyboard} from "@ionic-native/keyboard/ngx";
import * as $ from "jquery";
import { slideInAnimation } from './animations';

// defineFullCalendarElement();
register();


@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    animations: [slideInAnimation],
    styles: [`
        .route-container {
            position: relative;
            overflow: hidden;
            width: 100%;
            height: 100%;
        }
        .route-wrapper {
            position: relative;
            width: 100%;
            height: 100%;
        }
    `]
})
export class AppComponent {
    constructor(
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        private screenOrientation: ScreenOrientation,
        private location: LocationService,
        private localisationService: LocalisationService,
        private breakpointObserver: BreakpointObserver,
        private navController: NavController,
        private keyboard: Keyboard
    ) {
        this.initializeApp();


        if(this.platform.is('android')){
            this.keyboard.onKeyboardShow().subscribe((e) => {
                const safeArea = 40 ;
                const offset1 = $(document.activeElement).offset().top;
                const offset2 = window.innerHeight - e.keyboardHeight - $(document.activeElement).height() - safeArea ;
                const diff = offset1 - offset2;
                if (offset1 > window.innerHeight -  e.keyboardHeight - safeArea)
                    $('body').animate({ marginTop: -1 * diff + 'px' }, 100);
            });
            this.keyboard.onKeyboardHide().subscribe(e => {
                $('body').animate({ marginTop: 0 + 'px' }, 100);
            });
        }

    }
    appendWebSpecificAnalyticalCode(){
        const gtagScript = document.createElement('script');
        gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=G-7TYZWY45GM';
        gtagScript.async = true;
        document.head.appendChild(gtagScript);

        // Hotjar
        const hotjarScript = document.createElement('script');
        hotjarScript.src = 'https://static.hotjar.com/c/hotjar-.js?sv=';
        hotjarScript.async = true;
        document.head.appendChild(hotjarScript);
    }



    initializeApp() {
        if (!(this.platform.is('ios') || this.platform.is('android'))) {
            this.appendWebSpecificAnalyticalCode();
        }
        this.localisationService.iniLocaleOptions();

        if (this.platform.is('cordova')) {
            if (this.platform.is('ios') || this.platform.is('android')) {
                this.splashScreen.show();
            } else {
                this.splashScreen.hide();
            }

            this.platform.ready().then(() => {
                this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.LANDSCAPE);

                if (this.platform.is('ios') || this.platform.is('android')) {
                    this.statusBar.styleLightContent();
                }

                // Attempt to get the device location
                this.location.getGeolocation();

                setTimeout(() => {
                    this.splashScreen.hide();
                }, 1000);
            });
        } else {
            const minScreenWidth = 900;
            const minScreenHeight = 600;
            // Implement a min screen width of 800px and height of 600px for web apps
            this.breakpointObserver
                .observe([`(max-width: ${minScreenWidth}px)`, `(max-height: ${minScreenHeight}px)`])
                .subscribe((state: BreakpointState) => {
                    if (state.matches && (window.screen.width < minScreenWidth || window.screen.height < minScreenHeight)) {
                        this.navController.navigateForward(['/setup/deviceError']);
                    } else {
                        this.navController.navigateForward(['']);
                    }
                });
        }
    }

    getRouteState(outlet) {
        return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
    }
}

import {NgModule} from '@angular/core';
import {
    RouterModule,
    Routes
} from '@angular/router';
import {AuthGuard} from './guards/auth/auth.guard';
import {DataStoreGuard} from './guards/data-store/data-store.guard';
import {UserConfirmationGuard} from './guards/user-confirmation/user-confirmation.guard';
import {AccessCodeRequiredGuard} from './guards/access-code-required/access-code-required.guard';
import { SquadOverviewGuard } from './guards/squad-overview.guard';

const routes: Routes = [
    {
        path: 'welcome',
        loadChildren: () => import('./pages/auth/welcome/welcome.module').then(m => m.WelcomePageModule)
    },
    {
        path: 'login',
        loadChildren: () => import('./pages/auth/login/login.module').then(m => m.LoginPageModule)
    },
    {
        path: 'register',
        loadChildren: () => import('./pages/auth/register/register.module').then(m => m.RegisterPageModule)
    },
    {
        path: 'forgottonPassword',
        loadChildren: () => import('./pages/auth/forgot-password/forgot-password.module').then(m => m.ForgotPasswordPageModule)
    },
    {
        canActivate: [UserConfirmationGuard],
        path: 'userConfirmation',
        loadChildren: () => import('./pages/auth/user-confirmation/user-confirmation.module').then(m => m.UserConfirmationPageModule)
    },
    {
        path: 'setup',
        loadChildren: () => import('./pages/setup/setup.module').then(m => m.SetupPageModule)
    },
    {
        path: 'user-confirmation',
        loadChildren: () => import('./pages/auth/user-confirmation/user-confirmation.module').then(m => m.UserConfirmationPageModule)
    },
    {
        path: 'subscription',
        loadChildren: () => import('./pages/subscription/subscription.module').then( m => m.SubscriptionPageModule)
    },
    {
        path: 'user-confirmation-squad-access-code',
        loadChildren: () => import('./pages/auth/user-confirmation-squad-access-code/user-confirmation-squad-access-code.module').then(m => m.UserConfirmationSquadAccessCodePageModule)
    },
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [DataStoreGuard, AccessCodeRequiredGuard],
        loadChildren: () => import('./pages/tabs/tabs.module').then(m => m.TabsPageModule)
    },
    {
        path: 'activated',
        loadChildren: () => import('./pages/subscription/activated/activated.module').then( m => m.ActivatedPageModule)
    },
    {
        path: 'add-native',
        loadChildren: () => import('./pages/subscription/add-native/add-native.module').then( m => m.AddNativePageModule)
    },
    {
        path: 'manage-native',
        loadChildren: () => import('./pages/subscription/manage-native/manage-native.module').then( m => m.ManageNativePageModule)
    },
    {
        path: 'manage',
        loadChildren: () => import('./pages/subscription/manage/manage.module').then( m => m.ManagePageModule)
    },
    {
        path: 'subscription',
        loadChildren: () => import('./pages/settings/subscription/subscription.module').then( m => m.SubscriptionPageModule)
    },
    {
        path: 'cancel-subscription-questionnaire',
        loadChildren: () => import('./pages/cancel-subscription-questionaire/cancel-subscription-questionaire.module').then( m => m.CancelSubscriptionQuestionairePageModule)
    },
    {
        path: 'user-confirmation-squad-access-code',
        loadChildren: () => import('./pages/auth/user-confirmation-squad-access-code/user-confirmation-squad-access-code.module').then( m => m.UserConfirmationSquadAccessCodePageModule)
    },
    {
        path: 'settings/generate-access-codes',
        loadChildren: () => import('./pages/settings/generate-access-codes/generate-access-codes.module').then(m => m.GenerateAccessCodesPageModule)
    },
    {
        path: 'settings/currentSquad/overview',
        loadChildren: () => import('./pages/settings/current-squad/squad-overview/squad-overview.module').then(m => m.SquadOverviewPageModule),
        canDeactivate: [SquadOverviewGuard]
    }
];


@NgModule({
    imports: [
        RouterModule.forRoot(routes )
    ],
    exports: [RouterModule]
})

export class AppRoutingModule {
}

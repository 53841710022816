import {Injectable} from '@angular/core';
import {QonversionWebService} from '../qonversion-web/qonversion-web.service';
import {QonversionNativeService} from '../qonversion-native/qonversion-native.service';
import {Platform} from '@ionic/angular';
import {
    Entitlement,
    EntitlementRenewState,
    EntitlementSource,
    ManageEntitlement,
    QonversionNativeProduct
} from '../../../interfaces/qonversion-native-product';
import * as moment from 'moment';
import {DataStoreService} from '../../data-store/data-store.service';
import {HelperService} from '../../helper-service/helper.service';
import {StripeService} from '../../stripe/stripe.service';

@Injectable({
    providedIn: 'root'
})
export class QonversionManegmentService {
    private cachedPremiumAccessDate: number | null = null;

    constructor(
        private qonversionNativeService: QonversionNativeService,
        private qonversionWebService: QonversionWebService,
        private platform: Platform,
        private dataStoreService: DataStoreService,
        private helperService: HelperService,
        private stripeService: StripeService
    ) {}

    getLastDateUserHasAccessToPremium = async (): Promise<number> => {
        return this.cachedPremiumAccessDate;
    }

    updateCheckDoesUserHaveValidSubscription = async (): Promise<any> => {
        if (this.platform.is('cordova')) {
            const entitlements = await this.qonversionNativeService.getEntitlementsEnsureInitialized();
            const coachBasicSubscriptionEntitlement = entitlements.find(e => e.id === 'coachadvancedaccess');

            if (coachBasicSubscriptionEntitlement) {
                this.dataStoreService.user.isPro = coachBasicSubscriptionEntitlement.isActive;
                this.cachedPremiumAccessDate = moment(coachBasicSubscriptionEntitlement.expirationDate).valueOf();
            }
        } else {
            const entitlements = await this.qonversionWebService.getEntitlements();
            const coachBasicSubscriptionEntitlement = entitlements.find(e => e.id === 'coachadvancedaccess');

            if (coachBasicSubscriptionEntitlement) {
                this.dataStoreService.user.isPro = coachBasicSubscriptionEntitlement.active;
                this.cachedPremiumAccessDate = coachBasicSubscriptionEntitlement.expires * 1000;
            }
        }
    }

    getEntitlementsConvertedToManageEntitlement = async (): Promise<ManageEntitlement> => {
        if (this.platform.is('cordova')) {
            const entitlements = await this.qonversionNativeService.getEntitlementsEnsureInitialized();
            const nativeEntitlement = entitlements.find(e => e.id === 'coachadvancedaccess');

            if (nativeEntitlement) {
                this.cachedPremiumAccessDate = moment(nativeEntitlement.expirationDate).valueOf();

                const manageEntitlement: ManageEntitlement = {
                    active: nativeEntitlement.source !== EntitlementSource.STRIPE ? nativeEntitlement.renewState === EntitlementRenewState.WILL_RENEW : nativeEntitlement.isActive,
                    currency: '?',
                    nextPaymentDate: moment(nativeEntitlement.expirationDate).valueOf(),
                    platform: nativeEntitlement.source,
                    price: '?',
                    userSinceDate: moment(nativeEntitlement.startedDate).valueOf()
                };

                const products: QonversionNativeProduct[] = await this.qonversionNativeService.getProductsEnsureInitialized();
                const product = products.find(p => nativeEntitlement.productId === p.qonversionID);

                if (product) {
                    manageEntitlement.currency = product.currencyCode;
                    manageEntitlement.price = String(product.price);
                }

                return manageEntitlement;
            }
        } else {
            const entitlements = await this.qonversionWebService.getEntitlements();
            const webEntitlement = entitlements.find(e => e.id === 'coachadvancedaccess');

            if (webEntitlement) {
                this.cachedPremiumAccessDate = webEntitlement.expires * 1000;

                const manageEntitlement: ManageEntitlement = {
                    active: webEntitlement.active,
                    currency: '',
                    nextPaymentDate: webEntitlement.expires * 1000,
                    platform: this.helperService.capitalizeFirstLetter(webEntitlement.source),
                    price: '',
                    userSinceDate: webEntitlement.started * 1000
                };

                this.stripeService.getStripeProductsOnOffer().subscribe((products) => {
                    webEntitlement.product.productId = webEntitlement.product.productId.replace(/_v\d+$/, '');
                    const product = products.find(p => p.name.toLowerCase() === webEntitlement.product.productId);

                    if (product) {
                        const priceId = this.helperService.getStripeProductPriceIdBasedOnUsersLocation(product);
                        const price = product.prices.find(p => p.id === priceId);

                        manageEntitlement.price = this.helperService.formatStringToPrice(price.unit_amount.toString());
                        manageEntitlement.currency = price.currency;
                    }
                });

                const userProperties = await this.qonversionWebService.getUsersProperties();
                const customProperties = userProperties.customProperties;
                const stripeCustomPropertyData = customProperties.find(cP => cP.key === 'stripe_subscription_key');

                if (stripeCustomPropertyData) {
                    this.stripeService.stripeSubscriptionAutoRenewTurnedOff(stripeCustomPropertyData.value).subscribe((autoRenewOff) => {
                        manageEntitlement.active = !autoRenewOff;
                    });
                }

                return manageEntitlement;
            }
        }

        return null;
    }

    logOutOfQonversionService = async () => {
        this.cachedPremiumAccessDate = null;
        if (this.platform.is('cordova')) {
            await this.qonversionNativeService.logOut();
        } else {
            await this.qonversionWebService.logOut();
        }
    }
}

import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { OnlyEditingCurrentSessionWarningComponent } from '../../components/modals/only-editing-current-session-warning/only-editing-current-session-warning.component';

@Injectable({
    providedIn: 'root'
})
export class ModalService {
    constructor(private modalController: ModalController) {}

    /**
     * Shows warning modal for editing current practice session
     * @returns Promise that resolves when modal is dismissed
     */
    public async showEditingCurrentSessionWarning(): Promise<void> {
        const modal = await this.modalController.create({
            component: OnlyEditingCurrentSessionWarningComponent,
            cssClass: 'modal-structure-5',
            backdropDismiss: true
        });

        await modal.present();

        // Wait for modal to be dismissed before resolving
        return new Promise<void>((resolve) => {
            modal.onDidDismiss().then(() => {
                resolve();
            });
        });
    }

    /**
     * Generic method to present any modal and wait for dismissal
     * @param component The component to show as modal
     * @param props Optional component props
     * @param cssClass Optional CSS class
     * @returns Promise that resolves with modal dismiss data
     */
    public async presentModal(component: any, props?: any, cssClass?: string): Promise<any> {
        const modal = await this.modalController.create({
            component,
            componentProps: props,
            cssClass: cssClass || 'modal-structure-5',
            backdropDismiss: true
        });

        await modal.present();

        return modal.onDidDismiss();
    }
}
import { Injectable } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { SquadOverviewPage } from '../pages/settings/current-squad/squad-overview/squad-overview.page';
import { ToastController } from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class SquadOverviewGuard implements CanDeactivate<SquadOverviewPage> {
    constructor(private toastController: ToastController) {}

    private delay(ms: number): Promise<void> {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async canDeactivate(
        component: SquadOverviewPage,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState?: RouterStateSnapshot
    ): Promise<boolean> {
        
        // If we have pending updates, block navigation and show message
        if (component?.hasPendingUpdates()) {
            const toast = await this.toastController.create({
                message: 'Please wait while changes are being saved...',
                duration: 0,
                position: 'bottom'
            });
            await toast.present();

            try {
                // Wait for the full debounce period
                await this.delay(3000); 
                
                // Wait a bit more to ensure any triggered updates complete
                await this.delay(1000);
                
                // Check if we still have pending updates
                if (component.hasPendingUpdates()) {
                    await toast.dismiss();
                    const errorToast = await this.toastController.create({
                        message: 'Please wait for all changes to save before leaving',
                        duration: 2000,
                        position: 'bottom'
                    });
                    await errorToast.present();
                    return false;
                }

                await toast.dismiss();
                return true;
            } catch (error) {
                console.error('[Squad Overview Guard] Error:', error);
                await toast.dismiss();
                const errorToast = await this.toastController.create({
                    message: 'Error saving changes. Please try again.',
                    duration: 2000,
                    position: 'bottom'
                });
                await errorToast.present();
                return false;
            }
        }

        // Even if no pending updates, add a small delay to ensure any final updates complete
        await this.delay(1500);
        return true;
    }
} 
// PROD
export const environment = {
    production: true,
    version: require('../../package.json').version,
    awsUSServer: 'https://us.api.apexathleteseries.com/Production',
    awsEUServer: 'https://eu.api.apexathleteseries.com/Production',
    awsAPIName: 'StatSportsApi',
    awsRegion: 'eu-west-1',
    awsUserPoolId: 'eu-west-1_52jY9a1HQ',
    awsUserPoolWebClientId: 'bn7qqp3cmqvce1jb4pa7rs0vc',
    firebase: {
        apiKey: 'AIzaSyBm44UZ3hp9CGOTOET9u3y1vhJNLYjcsis',
        authDomain: 'apex-coach-series.firebaseapp.com',
        databaseURL: 'https://apex-coach-series.firebaseio.com',
        projectId: 'apex-coach-series',
        storageBucket: 'apex-coach-series.appspot.com',
        messagingSenderId: '391408934361',
        appId: '1:391408934361:web:ae1920ee6e35ceceafcdc8',
        measurementId: 'G-RDPJZRT27M'
    }
};

//
// UAT
// export const environment = {
//     production: true,
//     version: require('../../package.json').version,
//     awsUSServer: 'https://us.api.apexathleteseries.com/Production',
//     awsEUServer: 'https://eu.api.apexathleteseries.com/Production',
//     awsAPIName: 'StatSportsApi',
//     awsRegion: 'eu-west-1',
//     awsUserPoolId: 'eu-west-1_SxCWJ5Pvi',
//     awsUserPoolWebClientId: '2tccqi0vg6sujpo6jp4c5t4lhf',
//     firebase: {
//         apiKey: 'AIzaSyBm44UZ3hp9CGOTOET9u3y1vhJNLYjcsis',
//         authDomain: 'apex-coach-series.firebaseapp.com',
//         databaseURL: 'https://apex-coach-series.firebaseio.com',
//         projectId: 'apex-coach-series',
//         storageBucket: 'apex-coach-series.appspot.com',
//         messagingSenderId: '391408934361',
//         appId: '1:391408934361:web:db6efb6b90f672daafcdc8',
//         measurementId: 'G-ZY9KGXT6BZ'
// }
// };

//
// UAT

import {Component, OnInit} from '@angular/core';
import {QonversionNativeProduct} from '../../../../../interfaces/qonversion-native-product';
import {QonversionNativeService} from '../../../../../services/qonversion/qonversion-native/qonversion-native.service';
import {DataStoreService} from "../../../../../services/data-store/data-store.service";
import {
    QonversionManegmentService
} from "../../../../../services/qonversion/qonversion-manegment/qonversion-manegment.service";
import {ModalController} from "@ionic/angular";

@Component({
    selector: 'app-add-subscription-native-modal', template: `
        <div class="modal-container">
            <app-add-subscription-modal [products]="nativeProducts"
                                        [loading]="loading"
                                        [productSelected]="purchaseSubscription">
                                        
            </app-add-subscription-modal>
        </div>
    `, styles: [`
        .modal-container {

        }
    `]
})
export class AddSubscriptionNativeModalComponent implements OnInit {
    nativeProducts: QonversionNativeProduct [];
    loading: boolean = true;

    constructor(private qonversionNativeService: QonversionNativeService, private dataStoreService: DataStoreService, private qonversionManegmentService: QonversionManegmentService, private modalController: ModalController) {
    }

    ngOnInit() {
       this.doesUserHaveValidUserId();
    }




    async doesUserHaveValidUserId() {
        if (await this.qonversionNativeService.doesUserHaveCustomUserIdProperty()) {
            this.getProducts();
        }else{
            this.modalController.dismiss();
        }
    }

    async getProducts() {
        try {
            const products: QonversionNativeProduct[] = await this.qonversionNativeService.getProductsEnsureInitialized();
            this.nativeProducts = products.filter(p => !p.qonversionID.includes('uat'));
            this.loading = false;
        } catch (e) {
            alert(JSON.stringify(e));
        }
    }

    purchaseSubscription = async (product: any) => {
        try {
            const nativeProductSelected = this.nativeProducts.find(p => p.qonversionID === product.id);
            await this.qonversionNativeService.purchaseProduct(nativeProductSelected);
            await this.qonversionManegmentService.updateCheckDoesUserHaveValidSubscription();
        } catch (e) {
            alert(e);
        }
    }
}
